import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ApiHost } from '../../utils/ApiConfig';

const BusinessAdd = () => {
    const { accessToken } = useAuth();
    const { userId } = useAuth();
    let navigate = useNavigate();

    const [showAlert, setShowAlert] = useState(false);

    const [formValid, setFormValid] = useState(false);
    const [nip, setNip] = useState('');
    const [nipError, setNipError] = useState('');
    const [nipValid, setNipValid] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [businessNameError, setBusinessNameError] = useState('');
    const [street, setStreet] = useState('');
    const [streetError, setStreetError] = useState('');
    const [building, setBuilding] = useState('');
    const [buildingError, setBuildingError] = useState('');
    const [number, setNumber] = useState('');
    const [numberError, setNumberError] = useState('');
    const [postCode, setPostCode] = useState('');
    const [postCodeError, setPostCodeError] = useState('');
    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState('');

    const handleAccept = async (e) => {
        e.preventDefault();
        try {
            const data = {
                "BusinessName": businessName,
                "Street": street,
                "Building": building,
                "Number": number,
                "PostCode": postCode,
                "City": city
            }

            cleanValidators();
            console.log("Dodawanie powiązania użytkownika z firmą " + businessName);
            const response = await axios.post(ApiHost + `/business/add`, {
                nip: nip,
                user_id: userId,
                data: data
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            if (response.status === 200) {
                console.log("Dodano powiązanie użytkownika z firmą [" + userId + "]");
                navigate('/business/list', { replace: true });
            }

        } catch (error) {
            console.error("Błąd podczas dodawania firmy:", error);
        }
    };

    // eslint-disable-next-line no-extend-native
    String.prototype.isLetterOrDigit = function () {
        return (/^([a-zA-Z0-9]{1})$/gi).test(this);
    };

    function fillResult(response) {
        const data = response.data;

        setBusinessName(data.Name);
        setStreet(data.Street);
        setBuilding(data.Building);
        if (data.Number)
            setNumber(data.Number);
        setPostCode(data.PostCode);
        setCity(data.City);
    }

    function handleGusError(){
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    const gusSearch = async () => {
        try {
            console.log("Pobieranie danych z GUS [" + nip + "]")
            await axios.post(ApiHost + '/gus/search', {
                nip: nip
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    fillResult(response);
                })
                .then(cleanValidators())
                .catch(error => handleGusError());
        } catch (error) {
            console.warn('Błąd pobierania danych z GUS: ', error);
        }
    };

    const handleNipKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            gusSearch();
        }
    };
    
    const handleNipInput = (e) => {
        const value = e.target.value;

        // Umożliwia wpisanie tylko cyfr
        if (/^[0-9]*$/.test(value)) {
            setNip(value);
            setShowAlert(false);

            if (validateNIP(value))
                setNipValid(true);
            else
                setNipValid(false);
        }
    };

    const handleBusinessNameInput = (e) => {
        const value = e.target.value;

        if (/^[a-zA-Z0-9.,"'-/ ]*$/.test(value)) {
            setBusinessName(value);
        }
    };

    const handleStreetInput = (e) => {
        const value = e.target.value;

        if (/^[a-zA-Z0-9.,"'-/ ]*$/.test(value)) {
            setStreet(value);
        }
    };

    const handleBuildingInput = (e) => {
        const value = e.target.value;

        if (/^\d+[a-zA-Z\d,\/ -]*$/.test(value)) {
            setBuilding(value);
        }
    };

    const handleNumberInput = (e) => {
        const value = e.target.value;

        if (value === '' || /^\d*[0-9a-zA-Z]\w*$/.test(value)) {
            setNumber(value);
        }
    };

    const handlePostCodeInput = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, ''); // Usunięcie wszystkich znaków, które nie są cyframi
        if (value.length > 2) {
            // Wstawienie kreski po dwóch pierwszych cyfrach
            value = value.slice(0, 2) + '-' + value.slice(2);
        }
        setPostCode(value);
    };

    const handleCityInput = (e) => {
        const value = e.target.value;

        if (/^[a-zA-Z0-9.-/ ]*$/.test(value)) {
            setCity(value);
        }
    };

    const handleCancel = async (e) => {
        e.preventDefault();
        navigate('/business/list', { replace: true });
    };

    function validateNIP(nip) {
        nip = nip.replace(/[\s-]/g, ''); // Usuwanie spacji i myślników

        if (nip.length !== 10 || !/^\d+$/.test(nip)) {
            return false; // Sprawdzenie, czy NIP ma 10 cyfr
        }

        const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]; // Wagi dla poszczególnych cyfr NIP
        const controlSum = nip
            .substring(0, 9)
            .split('')
            .reduce((sum, digit, index) => sum + parseInt(digit) * weights[index], 0);

        const controlNumber = controlSum % 11;
        const controlDigit = parseInt(nip[9]);

        return controlNumber === controlDigit;
    }

    function validateBusinessName() {
        if (!businessName)
            return false;
        return true;
    }

    function validateStreet() {
        if (!street)
            return false;
        return true;
    }

    function validateBuilding() {
        if (!building)
            return false;
        if (building.length > 10)
            return false;
        if (!/^\d+[a-zA-Z\d,\/ -]*$/.test(building))
            return false; //"Nieprawidłowy format numeru budynku. Dozwolone są cyfry, litery oraz znaki ',', '-', '/', ' '.";
        return true;
    }
    
    function validateNumber() {
        if (!number)
            return true;
        if (number.length > 10)
            return false;        
        if (!/^\d*[0-9a-zA-Z]\w*$/.test(number))
            return false; //"Nieprawidłowy format numeru lokalu. Dozwolone są cyfry, litery oraz znaki ',', '-', '/', ' '.";    
        return true;
    }
    
    function validatePostCode() {
        if (!/^\d{2}-\d{3}$/.test(postCode))
            return false;
        return true;
    }
    
    function validateCity() {
        if (!city)
            return false;
        return true;
    }

    function cleanValidators(){
        setNipError("");
        setBusinessNameError("");
        setStreetError("");
        setBuildingError("");
        setNumberError("");
        setPostCodeError("");
        setCityError("");
        setFormValid(true);
    }

    const handleValidate = () => {
        cleanValidators();

        if (!validateNIP(nip)) {
            setNipError("Podaj poprawny numer NIP.");
            setFormValid(false);
        }
        if (!validateBusinessName()) {
            setBusinessNameError("Podaj poprawną nazwę firmy.");
            setFormValid(false);
        }
        if (!validateStreet()) {
            setStreetError("Podaj poprawną ulicę.");
            setFormValid(false);
        }
        if (!validateBuilding()) {
            setBuildingError("Podaj poprawny numer budynku.");
            setFormValid(false);
        }
        if (!validateNumber()) {
            setNumberError("Podaj poprawny lokal.");
            setFormValid(false);
        }
        if (!validatePostCode()) {
            setPostCodeError("Podaj poprawny kod pocztowy.");
            setFormValid(false);
        }
        if (!validateCity()) {
            setCityError("Podaj poprawną miejscowość.");
            setFormValid(false);
        }
    };

    return (
        <div>
            <div className='card container-fluid'>
            <div className='d-flex flex-row'>
                    <div className='my-2'>
                        <h3>Dodawanie powiązanej firmy</h3>
                    </div>
                </div>
                <div className="flex-column">
                    <form onSubmit={handleAccept}>
                        <div className="d-flex flex-column flex-wrap">
                            <div className='mb-3'>
                                <label className="form-label mb-0">NIP *</label>
                                <div className="input-group">
                                    <input type="text" className="form-control my-0 py-2" maxLength="10" value={nip} onChange={handleNipInput} onKeyDown={handleNipKeyPress} pattern='^[0-9]{10}$' placeholder="Numer NIP, np. 5422838585" onBlur={handleValidate} required={true} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-primary ms-3 my-0 h2" type="button" disabled={!nipValid} onClick={gusSearch}>Pobierz dane z GUS</button>
                                    </div>
                                </div>
                                {/* <input type="text" className="form-control my-0 py-2 rounded-3" maxLength="10" value={nip} onChange={handleNipInput} pattern='^[0-9]{10}$' placeholder="np. 5422838585" /> */}
                                {nipError && (<div className="text-danger">{nipError}</div>)}
                                {
                                    true && (
                                        <div className={`alert alert-warning alert-dismissible d-flex align-items-center fixed-top w-25 mt-5 ms-auto fade ${showAlert ? "elementFromRight" : ""}`} role="alert">
                                            <div>
                                                <strong>Brak danych w GUS</strong><br />Sprawdź poprawność danych.
                                            </div>
                                            <button type="button" className="btn-close" onClick={() => setShowAlert(false)} aria-label="Close"></button>
                                        </div>
                                    )
                                }
                            </div>

                            <div className='mb-3'>
                                <label className="form-label mb-0">Nazwa firmy *</label>
                                <input type="text" className="form-control my-0 py-2 rounded-3" maxLength="50" value={businessName} onChange={handleBusinessNameInput} placeholder="Nazwa firmy, np. Gamma-Alfa sp. z o.o." onBlur={handleValidate} required={true} />
                                {businessNameError && (<div className="text-danger">{businessNameError}</div>)}
                            </div>
                            <div className='mb-3 d-flex flex-row'>
                                <div className='w-50'>
                                    <label className="form-label mb-0">Ulica *</label>
                                    <input type="text" className="form-control my-0 py-2 rounded-3" maxLength="50" value={street} onChange={handleStreetInput} placeholder="Ulica, np. ul. Białostocka" onBlur={handleValidate} required={true} />
                                    {streetError && (<div className="text-danger">{streetError}</div>)}
                                </div>

                                <div className='w-25 ms-3'>
                                    <label className="form-label mb-0">Budynek *</label>
                                    <input type="text" className="form-control my-0 py-2 rounded-3" maxLength="10" value={building} onChange={handleBuildingInput} placeholder="Numer budynku" onBlur={handleValidate} required={true} />
                                    {buildingError && (<div className="text-danger">{buildingError}</div>)}
                                </div>

                                <div className='w-25 ms-3'>
                                    <label className="form-label mb-0">Lokal</label>
                                    <input type="text" className="form-control my-0 py-2 rounded-3" maxLength="10" value={number} onChange={handleNumberInput} placeholder="Numer lokalu" onBlur={handleValidate} required={false} />
                                    {numberError && (<div className="text-danger">{numberError}</div>)}
                                </div>
                            </div>

                            <div className='mb-3'>
                                <label className="form-label mb-0">Kod pocztowy *</label>
                                <input type="text" className="form-control my-0 py-2 rounded-3" maxLength="6" value={postCode} onChange={handlePostCodeInput} placeholder="Kod pocztowy, np. 15-048" onBlur={handleValidate} required={true} />
                                {postCodeError && (<div className="text-danger">{postCodeError}</div>)}
                            </div>

                            <div className='mb-3'>
                                <label className="form-label mb-0">Miejscowość *</label>
                                <input type="text" className="form-control my-0 py-2 rounded-3" maxLength="50" value={city} onChange={handleCityInput} placeholder="Miejscowość, np. Białystok" onBlur={handleValidate} required={true} />
                                {cityError && (<div className="text-danger">{cityError}</div>)}
                            </div>

                            <div className="d-flex flex-row mt-3 w-100">
                                <button className="btn btn-outline-primary py-2 rounded-2 me-auto" style={{ width: '140px' }} onClick={handleCancel}>
                                    Anuluj
                                </button>
                                <button type="submit" className="btn btn-primary py-2 rounded-2 ms-auto" style={{ width: '140px' }} disabled={!formValid}>
                                    Dodaj
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BusinessAdd;
