import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { ApiHost } from '../utils/ApiConfig';
import { getPermissions } from '../services/User';

const AuthContext = createContext();

// Hook do łatwego dostępu do kontekstu
export const useAuth = () => useContext(AuthContext);

// Komponent dostarczający stan i funkcje związane z autoryzacją
export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || '');
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken') || '');
  const [userName, setUserName] = useState(localStorage.getItem('userName') || '');
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const [chamberId, setChamberId] = useState(localStorage.getItem('chamberId') || '');
  const [chamberName, setChamberName] = useState(localStorage.getItem('chamberName') || '');
  const [userPermissions, setUserPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')) || {});
  const [isLoggedIn, setIsLoggedIn] = useState(!!accessToken);

  useEffect(() => {
    // Zaktualizuj stan `isLoggedIn` na podstawie obecności `accessToken`
    setIsLoggedIn(!!accessToken);
  }, [accessToken]);

  // Funkcja logowania
  const login_step1 = async (username) => {
    try {
      const response = await axios.post(ApiHost + '/login_step1', { username });
      if (response.status === 200)
        return true;
      return false;
      } catch (error) {
      console.error('Błąd logowania', error);
      return false;
    }
  };

  // Sprawdzanie czy zalogowany użytkownik posiada uprawnienie
  const checkPermission = (permissionName) => {
    const result = userPermissions.some(permission => {
      if (permission.Name === permissionName)
        return true;
      return false;
    });
    return result;
  };

  // Funkcja logowania
  const login = async (username, password) => {
    try {
      const response = await axios.post(ApiHost + '/login', { username, password });
      setAccessToken(response.data.access_token);  // Aktualizacja stanu accessToken
      setRefreshToken(response.data.refresh_token);  // Aktualizacja stanu refreshToken
      setUserName(response.data.user_name);
      console.log("Zalogowano użytkownika "+response.data.user_name+", Id: "+response.data.user_id);
      setUserId(response.data.user_id);
      setChamberId(response.data.chamber_id);
      setChamberName(response.data.chamber_name);
      setIsLoggedIn(true);
      localStorage.setItem('accessToken', response.data.access_token);
      localStorage.setItem('refreshToken', response.data.refresh_token);
      localStorage.setItem('userName', response.data.user_name);
      localStorage.setItem('userId', response.data.user_id);
      localStorage.setItem('chamberId', response.data.chamber_id);
      localStorage.setItem('chamberName', response.data.chamber_name);

      console.log("Zapisano stan użytkownika "+userName+", Id: "+userId);
      try {
        console.log("Pobieranie uprawnień użytkownika");
        const permissions = await getPermissions(response.data.user_id);
        localStorage.setItem('userPermissions', JSON.stringify(permissions));
        setUserPermissions(permissions);
      } catch (e) {
        console.error("Błąd podczas pobierania uprawnień użytkownika [" + response.data.user_id + "]: " + e);
      }
      return true;
    } catch (error) {
      console.error('Błąd logowania', error);
      return false;
    }
  };

  // Funkcja wylogowania
  const logout = () => {
    setAccessToken('');  // Wyczyszczenie stanu accessToken
    setIsLoggedIn(false);
    localStorage.removeItem('accessToken');  // Usunięcie tokena z localStorage
    localStorage.removeItem('refreshToken');  // Usunięcie tokena z localStorage
    localStorage.removeItem('userName');  
    localStorage.removeItem('userId');  
    localStorage.removeItem('chamberId');
    localStorage.removeItem('chamberName');
    localStorage.removeItem('userPermissions');  
  };

  // Funkcja odświeżania tokena
  const refresh = async () => {
    try {
      const response = await axios.post(ApiHost + '/refresh', {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('refreshToken')}`  // Dołączenie aktualnego tokena do żądania
        }
      });
      setAccessToken(response.data.access_token);  // Aktualizacja stanu refreshToken nowym tokenem
      localStorage.setItem('accessToken', response.data.access_token);  // Zapisanie nowego tokena w localStorage
    } catch (error) {
      console.error('Błąd odświeżania tokena', error);
    }
  };

  const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  };
  
  useEffect(() => {
    let intervalId;

    const checkToken = async () => {
      if (isTokenExpired(accessToken)) {
        const newAccessToken = await refresh();
        if (newAccessToken) {
          setAccessToken(newAccessToken);
          localStorage.setItem('accessToken', newAccessToken);
        }
      }
    };

    if (accessToken) {
      // Sprawdź ważność tokena co określony czas, np. co 5 minut
      intervalId = setInterval(checkToken, 1 * 60 * 1000);
    }

    // Funkcja sprzątająca
    return () => clearInterval(intervalId);
  }, [accessToken]);  

  // Pobieranie listy zgód użytkownika
  const getConsentIds = async () => {
    try {
      const response = await axios.post(ApiHost + '/users/consent/ids', 
      { user_id: userId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`  // Dołączenie tokena do żądania
        }
      });
      console.log('Chroniona metoda zwróciła:', response.data);
      return response.data;
    } catch (error) {
      console.error('Błąd dostępu do chronionej metody', error);
    }
  };

  // Dostarczanie stanu i funkcji przez kontekst
  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken, login_step1, login, logout, userId, userName, chamberId, chamberName, userPermissions, isLoggedIn, refreshToken, getConsentIds, checkPermission }}>
      {children}
    </AuthContext.Provider>
  );
};
