import React, { useEffect, useState } from 'react';
import ProductComponent from "./ProductComponent";
import { createEmptyProductComponent } from '../../services/Application';
import CommunicationSpot from './CommunicationSpot';

const ProductDetails = ({ index, data, onDataChange, onRemove, name,  commentVisible = true, communicationHandler, registerCallback, viewAll = false, handleValidate }) => {
    const processIndex = name.split("_")[1];
    const [productData, setProductData] = useState(data);
    const [removedComponentIndexes, setRemovedComponentIndexes] = useState([]);
    const [validators, setValidators] = useState({});

    const setValidator = (name, validator) => {
        setValidators(prevValidators => {
            return {
                ...prevValidators,
                [name]: validator
            };
        });
    };

    const removeValidator = (name) => {
        setValidators(prevValidators => {
            const updatedValidators = { ...prevValidators };
            delete updatedValidators[name];
            return updatedValidators;
        });
    };

    const handleAddComponent = () => {
        setProductData(prevProductData => {
            const updatedComponents = [...prevProductData.Components, createEmptyProductComponent()];
            const updatedData = { ...prevProductData, Components: updatedComponents };
            onDataChange(updatedData, index);
            return updatedData;
        });
    };

    const handleUpdateComponent = (component, cIndex) => {
        setProductData(prevProductData => {
            const updatedComponents = [...prevProductData.Components];
            updatedComponents[cIndex] = component;
            const updatedData = { ...prevProductData, Components: updatedComponents };
            onDataChange(updatedData, index);
            return updatedData;
        });
    };

    const handleRemoveComponent = (cIndex) => {
        setRemovedComponentIndexes(prevIndexes => [...prevIndexes, cIndex]);
        removeValidator("ProductComponent_" + processIndex + "_" + index + "_" + cIndex);
    };

    useEffect(() => {
        const filteredComponents = productData.Components.filter((_, index) => !removedComponentIndexes.includes(index));
        const updatedData = { ...productData, Components: filteredComponents };
        onDataChange(updatedData, index);
    }, [removedComponentIndexes]);

    useEffect(() => {
        let count = 0;
        for (const key in validators) {
            if (validators.hasOwnProperty(key) && ((validators[key] === "true") || validators[key] === true)) {
                count++;
            }
        }
        handleValidate(name, (count === 0) ? "false" : "true");
    }, [validators]);


    return (
        <div>
            <div className="d-flex flex-row w-100 mb-1 fs-6">
                <div className='me-auto'>
                    <strong>{(index + 1) + ". " + productData.name}</strong>
                </div>
                <CommunicationSpot
                    commentId={"3-1-" + processIndex + "-" + index} location="Proces produkcji - Produkt" isVisible={commentVisible} onClickHandler={communicationHandler} registerCallback={registerCallback}
                    viewAll={viewAll}
                />
                <button type="button" key={"ProductDetailsRemove_" + processIndex + "_" + index} className="btn btn-outline-primary w7 h2 rounded-2 mt-auto ms-1" onClick={() => onRemove(index)}>
                    Usuń produkt
                </button>
            </div>

            <div className="w-100">
                {productData.Components.map((component, pcIndex) => (
                    !removedComponentIndexes.includes(pcIndex) && (
                    <div>
                        <ProductComponent 
                            name={"ProductComponent_" + processIndex + "_" + index + "_" + pcIndex}
                            index={pcIndex} key={"ProductComponent_" + processIndex + "_" + index + "_" + pcIndex}
                            data={component} onDataChange={handleUpdateComponent}
                            onRemove={() => handleRemoveComponent(pcIndex)}
                            handleValidate={setValidator}
                        />
                    </div>
                    )
                ))}

                <div className="d-flex flex-row mt-1 me-4">
                    <button type="button" className="btn btn-primary w7 h2 rounded-2 mt-auto ms-auto" onClick={handleAddComponent} title="Dodaj kolejny materiał/surowiec/komponent">
                        Dodaj kolejny
                    </button>
                </div>
            </div>
        </div>);
}

export default ProductDetails;