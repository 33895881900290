import React, { useState, useEffect } from 'react';
import HelpTooltip from './HelpTooltip';
import ValidationInfo from './ValidationInfo';
import { getChambers } from '../../services/Dictionary';

function ChamberSelector({ name, data, onDataChange, required, label, tooltip, validateTrigger = 0 }) {

    const [chambers, setChambers] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    const [valid, setValid] = useState(true);
    const [loading, setLoading] = useState(true);

    const handleInput = (e) => {
        e.target.name = name;
        setSelectedValue(e.target.value);
        validate(e);
        onDataChange(e);
    }

    const validate = (e) => {
        if (required !== "true")
            setValid(true);
        else
            setValid((e.target.value.length >= 2));
    };

    useEffect(() => {
        if (validateTrigger > 0)
            validate({target: {value: selectedValue}});
    }, [validateTrigger]);

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            let tempChambers = await getChambers();
            setChambers(tempChambers);
            let kig = tempChambers.find(chamber => chamber.Name === "Krajowa Izba Gospodarcza");
            if (data)
                setSelectedValue(data);
            else 
                if (kig) {
                    setSelectedValue(kig.Id);
                    onDataChange({target: {name: "chamberSelect", value: kig.Id}});
                }
            setLoading(false);
        };

        fetchData();
    }, []); 

    if (loading){
        return (
            <div>
                <label className="form-label">{label + ((required === "true") ? " *" : "")}</label>
                <div className="form-control my-0 py-2 rounded-3">
                    <span className='spinner-border spinner-border-sm me-2'></span>
                    <span>Ładowanie</span>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='d-flex flex-row w-100'>
                <label className="form-label">{label + ((required === "true") ? " *" : "")}</label>
                {tooltip && (
                    <HelpTooltip title={tooltip} />
                )}
                {(!valid) && (
                    <ValidationInfo title="Wypełnienie pola jest obowiązkowe. Wybierz wartość z listy." visible={true} />
                )}
            </div>
            <select
                className="form-control form-select my-0 h2 rounded-3" 
                name="chamberSelect" 
                value={selectedValue} 
                onChange={handleInput} 
                onBlur={validate} 
                required={required === "true"} 
                invalid={valid ? "false" : "true"}
            >
                {chambers.map(c => (
                    <option key={c.Id} value={c.Id}>
                        {c.Name}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default ChamberSelector;
