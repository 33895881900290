import React, { useEffect, useState } from 'react';
import ProducerAddress from './ProducerAddress';
import HelpTooltip from './HelpTooltip';
import ValidationInfo from './ValidationInfo';

const ShippingDetails = ({applicant, data, onDataChange, invalid = "false"}) => {
    const [shippingMethod, setShippingMethod] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [validators, setValidators] = useState({});

    const handleAddressChange = (event) => {
        //setAddress(event.target.value);
        data[event.target.name] = event.target.value;
        onDataChange(event);
    };


    const handleShippingMethodChange = (event) => {
        setShippingMethod(event.target.value);
        if (event.target.value === 'courier') {
            //setPhoneNumber(applicant.phoneNumber);
            data.phoneNumber = applicant.phoneNumber;
        }
        onDataChange(event);
    };

    const handlePhoneNumberChange = (event) => {
        if (/^[0-9+/ ]*$/.test(event.target.value)){
            setPhoneNumber(event.target.value);
            onDataChange(event);
        }
    };

    useEffect(() => {
        if (data) {
            setShippingMethod(data.shippingMethod);
            setPhoneNumber(data.phoneNumber);
            console.log(data.phoneNumber);
        }
    }, [data]);

    useEffect(() => {
        if (data.phoneNumber) {
            setPhoneNumber(data.phoneNumber);
        }
        else
            setPhoneNumber(applicant.phoneNumber);
    }, []);

    const setValidator = (name, validator) => {
        setValidators(prevValidators => {
            return {
                ...prevValidators,
                [name]: validator
            };
        });
    };

    const removeValidator = (name) => {
        setValidators(prevValidators => {
            const updatedValidators = { ...prevValidators };
            delete updatedValidators[name];
            return updatedValidators;
        });
    };

    const validate = () => {
        setValidator("shippingMethod", (shippingMethod === "") ? "true" : "false");

        if (shippingMethod === 'courier') {
            if (!phoneNumber) {
                setValidator('phoneNumber', () => {
                    if (!phoneNumber || phoneNumber === "") {
                        return "true";
                    }
                    return "false";
                });
            }
            else {
                removeValidator('phoneNumber');
            }
        }
    };

    useEffect(() => {
        validate();
    }, [shippingMethod, phoneNumber]);

    return (
        <div className='d-flex flex-column'>
            <div className='d-flex flex-row'>
                <label className='mb-1' htmlFor="shippingMethod">Wybierz formę dostawy</label>
                <ValidationInfo title="Uzupełnij dane dotyczące dostawy" visible={invalid} />
            </div>
            <div className="form-check form-check-inline mb-1">
                <input className="form-check-input" type="radio" id="pickup" name="shippingMethod" value="pickup" checked={shippingMethod === 'pickup'} onChange={handleShippingMethodChange} />
                <label className="form-check-label" htmlFor="pickup">Odbiór osobisty</label>
            </div>
            <div className="form-check form-check-inline mb-1">
                <input className="form-check-input" type="radio" id="courier" name="shippingMethod" value="courier" checked={shippingMethod === 'courier'} onChange={handleShippingMethodChange} />
                <label className="form-check-label" htmlFor="courier"><div className='d-flex flex-row'>Kurier <HelpTooltip title="Opłata za dostawę wynosi 30 zł." /></div></label>
            </div>
            {shippingMethod === 'courier' && (
                <div className='mb-1'>
                    <ProducerAddress isProducer="true" shippingAddress="true" data={data} applicant={applicant} onDataChange={handleAddressChange} />
                    <div className='mt-1'>
                        <div className='d-flex flex-row'>
                        <label className="form-label">Numer telefonu dla kuriera *</label>
                        <HelpTooltip title="Podanie numeru jest niezbędne, aby możliwe było zrealizowanie usługi wysyłki." />
                        <ValidationInfo title="Wypełnienie pola jest obowiązkowe w przypadku wyboru dostawy przez kuriera." visible={validators.phoneNumber ? "true" : "false"} />
                        </div>
                        <input
                            type="text"
                            className="form-control my-0 rounded-3 w13"
                            name='phoneNumber'
                            maxLength="50"
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            placeholder="Numer telefonu"
                            required={true}
                            invalid={validators.phoneNumber ? "true" : "false"}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShippingDetails;