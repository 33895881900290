import React, { useEffect } from 'react';
import './StatusBadge.css'; // Ensure you create this CSS file for styling

const StatusBadge = ({ status, statusColor, text, colorMode = 'Client' }) => {
    const [bgColor, setBgColor] = React.useState(statusColor);
    const [textColor, setTextColor] = React.useState('black');

    const colorMapping = {
        Client: [
            { status: 'Roboczy', bgColor: 'light-green', textColor: 'black' },
            { status: 'Wysłany', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Popraw', bgColor: 'red', textColor: 'white' },
            { status: 'Weryfikacja opłaty', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Opłać', bgColor: 'orange', textColor: 'black' },
            { status: 'Oczekiwanie na anulowanie', bgColor: 'light-violet', textColor: 'black' },
            { status: 'Anulowany', bgColor: 'violet', textColor: 'white' },
            { status: 'Do opłaty i podpisu', bgColor: 'orange', textColor: 'black' },
            { status: 'Weryfikacja podpisu', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Do opłacenia', bgColor: 'orange', textColor: 'black' },
            { status: 'Podpisz', bgColor: 'orange', textColor: 'black' },
            { status: 'W akceptacji', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Oczekuje na wydruk', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Odbierz', bgColor: 'green2', textColor: 'black' },
            { status: 'Oczekuje na wysyłkę', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Wysłano', bgColor: 'green2', textColor: 'black' },
            { status: 'Wystawione', bgColor: 'green2', textColor: 'black' }
        ],
        Operator: [
            { status: 'Roboczy', bgColor: 'light-green', textColor: 'black' },
            { status: 'Nowy', bgColor: 'green', textColor: 'black' },
            { status: 'Weryfikacja', bgColor: 'blue', textColor: 'black' },
            { status: 'Do poprawy', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Weryfikacja opłaty', bgColor: 'orange', textColor: 'black' },
            { status: 'Opłata za usługę', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Do anulowania', bgColor: 'light-violet', textColor: 'black' },
            { status: 'Anulowany', bgColor: 'violet', textColor: 'white' },
            { status: 'Do opłaty i podpisu', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Weryfikacja podpisu', bgColor: 'orange', textColor: 'black' },
            { status: 'Do opłacenia', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Do podpisu', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Do wydania', bgColor: 'yellow', textColor: 'black' },
            { status: 'Do wydruku', bgColor: 'yellow', textColor: 'black' },
            { status: 'Do odbioru', bgColor: 'light-grey', textColor: 'black' },
            { status: 'Do wysyłki', bgColor: 'yellow', textColor: 'black' },
            { status: 'Wysłano', bgColor: 'green2', textColor: 'black' },
            { status: 'Wystawione', bgColor: 'green2', textColor: 'black' }
        ]
    };

    useEffect(() => {
        if (statusColor === undefined) {
            if (colorMode === 'Operator') {
                const statusColor = colorMapping.Operator.find((item) => item.status.toLowerCase() === text.toLowerCase());
                if (statusColor !== undefined) {
                    setBgColor(statusColor.bgColor);
                    setTextColor(statusColor.textColor);
                }
            } else {
                const statusColor = colorMapping.Client.find((item) => item.status.toLowerCase() === text.toLowerCase());
                if (statusColor !== undefined) {
                    setBgColor(statusColor.bgColor);
                    setTextColor(statusColor.textColor);
                }
            }
        } else {
            let mapping;
            if (colorMode === 'Operator')
                mapping = colorMapping.Operator.find((item) => item.bgColor === mapping);
            else
                mapping = colorMapping.Client.find((item) => item.bgColor === mapping);
            if (mapping !== undefined)
                setTextColor(mapping.textColor);
        }
    }, [status, text]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return <div className={`fw-semibold status-badge ${bgColor} text-${textColor}`}>{capitalizeFirstLetter(text)}</div>;
};

export default StatusBadge;