import React, { useState, useEffect } from 'react';
import HelpTooltip from './HelpTooltip';

function ComponentSelector({ name, index, data, componentsList, onDataChange, required, label, tooltip, onBlurHandler }) {

    const [components, setComponents] = useState([]);

    const handleInput = (e) => {
        e.target.name = name;
        onDataChange(e.target.value);
    }

    useEffect(() => {

        setComponents(componentsList);
    }, [componentsList]);

    return (
        <div>
            <div className='d-flex flex-row'>
                <label className="form-label">{label + ((required === "true") ? " *" : "")}</label>
                {tooltip && (
                    <HelpTooltip title={tooltip} />
                )}
            </div>
            <select className="form-control form-select my-0 h2 rounded-3" name={"componentSelect" + index} value={data} onChange={handleInput} onBlur={onBlurHandler} required={required === "true"}>
                <option value="">Wybierz ...</option>
                {components.map(component => (
                    <option key={component} value={component}>
                        {component}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default ComponentSelector;
