import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import HelpTooltip from './HelpTooltip';
import ValidationInfo from './ValidationInfo';
import { ApiHost } from '../../utils/ApiConfig';
import GusSearch from './GusSearch';
import LoadingSpinner from './LoadingSpinner';

function BusinessSelector({ name, data, onDataChange, required, label, tooltip, validateTrigger, onValidate }) {
    const { accessToken } = useAuth();
    const { userId } = useAuth();
    const [businesses, setBusinesses] = useState([]);
    const [gusSearchVisible, setGusSearchVisible] = useState(false);
    const [valid, setValid] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleInput = (e) => {
        let value = e.target.value;
        if (value.length === 0)
            value = '{"Name": "", "NIP": "", "Street": "", "Building": "", "Appartment": "", "PostCode": "", "City": "", "Id": ""}';
        e.target.name = name;
        validate(e);

        onDataChange(name, value);
    }

    const validate = (e) => {
        let value = e.target.value;

        if (value.length === 0)
            value = '{"Name": "", "NIP": "", "Street": "", "Building": "", "Appartment": "", "PostCode": "", "City": "", "Id": ""}';
        const jsonValue = JSON.parse(value);

        if (!required) {
            setValid(true);
            onValidate({ target: { name: "applicant", value: "false" } });
        }
        else {
            setValid((jsonValue.Name.length > 0));
            onValidate({ target: { name: "applicant", value: ((jsonValue.Name.length > 0) ? "false" : "true") } });
        }
    };

    const handleSearch = (result) => {
        if (!businesses.find(business => business.NIP === result.NIP))
            setBusinesses([...businesses, result]);
        handleInput({ target: { name: name, value: JSON.stringify(result) } });
    }

    useEffect(() => {
        if (validateTrigger > 0)
            validate({ target: { value: data } });
    }, [validateTrigger]);


    useEffect(() => {
        const getBusinesses = async () => {
            try {
                setLoading(true);
                const response = await axios.post(ApiHost + '/business/list', {
                    user_id: userId
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                    .then(response => {
                        const receivedBusinesses = response.data;
                        data = [];
                        receivedBusinesses.forEach(business => {
                            // if (data.find(b => b.NIP === business.NIP))
                            //     return;
                            data.push({
                                "Name": business.Name,
                                "NIP": business.NIP,
                                "Street": business.Data.Street,
                                "Building": business.Data.Building,
                                "Appartment": business.Data.Number,
                                "PostCode": business.Data.PostCode,
                                "City": business.Data.City,
                                "Id": business.Id
                            });
                        });
                        setBusinesses(data);
                    })
                    .catch(error => console.error('Błąd pobierania listy powiązanych firm: ', error));
            } catch (error) {
                console.error('Błąd pobierania listy powiązanych firm: ', error);
            } finally {
                setLoading(false);
            }
        };

        getBusinesses();
    }, []);

    return (
        <div>
            <div className='d-flex flex-row'>
                <label className="form-label">{label + ((required === "true") ? " *" : "")}</label>
                {tooltip && (
                    <HelpTooltip title={tooltip} />
                )}
                {!valid &&
                    (
                        <ValidationInfo title="Wypełnienie pola jest obowiązkowe. Wybierz wartość z listy." visible={true} />
                    )}
            </div>

            {loading ? (
                <div className="form-control form-select my-0 rounded-3 h2 w37">
                <LoadingSpinner text='Pobieranie powiązanych firm' />
                </div>
            ) : (
                <div className='d-flex flex-row'>
                    <select
                        className="form-control form-select my-0 rounded-3 h2 w37"
                        name="businessSelect"
                        value={data}
                        onChange={handleInput}
                        onBlur={validate}
                        required={required === "true"}
                        invalid={valid ? "false" : "true"}
                    >
                        <option value="">Wybierz z listy lub dodaj nową</option>
                        {businesses.map(business => (
                            <option key={business.Id} value={JSON.stringify(business)}>
                                {business.Name} ({business.NIP})
                            </option>
                        ))}
                    </select>
                    <button className="btn btn-outline-primary rounded-2 me-auto ms-1 w9" onClick={() => { setGusSearchVisible(true) }}>
                        Dodaj nową firmę
                    </button>
                </div>
            )}


            <div>
                <GusSearch
                    visible={gusSearchVisible}
                    handleCancel={() => { setGusSearchVisible(false); }}
                    handleSearch={handleSearch}
                />
            </div>
        </div>
    );
}

export default BusinessSelector;
