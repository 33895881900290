import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { ApiHost } from '../utils/ApiConfig';

const debug = false;

const getUsersList = async (accessToken) => {
    try {
        if (debug) console.log("Pobieranie listy użytkowników.");
        const response = await axios.post(ApiHost + `/users/list`, {}, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("getUsersList result: " + JSON.stringify(result));
            return result;
        }
        return "false";
    } catch (error) {
        console.error("Błąd podczas pobierania listy użytkowników: ", error);
    }
};

const getUser = async (userId, accessToken) => {
    try {
        if (debug) console.log("Pobieranie danych użytkownika.");
        const response = await axios.post(ApiHost + `/users/get`, {
            userid: userId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("getUser result: " + JSON.stringify(result));
            return result;
        }
        return "false";
    } catch (error) {
        console.error("Błąd podczas pobierania danych użytkownika: ", error);
    }
};

const addUser = async (email, firstName, lastName, notify = false, accessToken) => {
    try {
        if (debug) console.log("Dodawanie użytkownika.");
        const response = await axios.post(ApiHost + `/users/add`, {
            email: email,
            firstname: firstName,
            lastname: lastName,
            notify: notify
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("addUser result: " + JSON.stringify(result));
            return true;
        }
        return false;
    } catch (error) {
        console.error("Błąd podczas dodawania użytkownika: ", error);
    }
};

const editUser = async (userId, email, firstName, lastName, accessToken) => {
    try {
        if (debug) console.log("Edycja użytkownika.");
        const response = await axios.post(ApiHost + `/users/update`, {
            userid: userId,
            email: email,
            firstname: firstName,
            lastname: lastName
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("editUser result: " + JSON.stringify(result));
            return true;
        }
        return false;
    } catch (error) {
        console.error("Błąd podczas edycji użytkownika: ", error);
    }
};

const activateUser = async (userId, accessToken) => {
    try {
        if (debug) console.log("Aktywacja użytkownika.");
        const response = await axios.post(ApiHost + `/users/activate`, {
            userid: userId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("activateUser result: " + JSON.stringify(result));
            return true;
        }
        return false;
    } catch (error) {
        console.error("Błąd podczas aktywacji użytkownika: ", error);
    }
};

const blockUser = async (userId, accessToken) => {
    try {
        if (debug) console.log("Blokowanie użytkownika.");
        const response = await axios.post(ApiHost + `/users/delete`, {
            userid: userId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("blockUser result: " + JSON.stringify(result));
            return true;
        }
        return false;
    } catch (error) {
        console.error("Błąd podczas blokowania użytkownika: ", error);
    }
};

const assignChamber = async (email, chamberId, accessToken) => {
    try {
        if (debug) console.log("Przypisywanie izby do użytkownika.");
        const response = await axios.post(ApiHost + `/users/assignchamber`, {
            email: email,
            chamberid: chamberId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("assignChamber result: " + JSON.stringify(result));
            return true;
        }
        return false;
    } catch (error) {
        console.error("Błąd podczas przypisywania izby do użytkownika: ", error);
    }
};

const assignRole = async (email, roleId, accessToken) => {
    try {
        if (debug) console.log("Przypisywanie roli do użytkownika.");
        const response = await axios.post(ApiHost + `/users/assignrole`, {
            email: email,
            roleid: roleId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("assignRole result: " + JSON.stringify(result));
            return true;
        }
        return false;
    } catch (error) {
        console.error("Błąd podczas przypisywania roli do użytkownika: ", error);
    }
};

const getAssignedRoles = async (email, accessToken) => {
    try {
        if (debug) console.log("Pobieranie ról przypisanych do użytkownika [" + email + "]");
        const response = await axios.post(ApiHost + `/users/roles`, {
            email: email,
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("getAssignedRoles result: " + JSON.stringify(result));
            return result;
        }
        return "false";
    } catch (error) {
        console.error("Błąd podczas pobierania ról przypisanych do użytkownika [" + email + "]: ", error);
    }
};

const removeRole = async (email, roleId, accessToken) => {
    try {
        if (debug) console.log("Usuwanie przypisania roli do użytkownika.");
        const response = await axios.post(ApiHost + `/users/removerole`, {
            email: email,
            roleid: roleId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("removeRole result: " + JSON.stringify(result));
            return true;
        }
        return false;
    } catch (error) {
        console.error("Błąd podczas usuwania przypisania roli do użytkownika: ", error);
    }
};

const getPermissions = async (userId, accessToken) => {
    try {
        if (debug) console.log("Pobieranie uprawnień użytkownika [" + userId + "]");
        const response = await axios.post(ApiHost + `/users/permissions`, {
            userid: userId,
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("getPermissions result: " + JSON.stringify(result));
            return result;
        }
        return "false";
    } catch (error) {
        console.error("Błąd podczas pobierania uprawnień użytkownika [" + userId + "]: ", error);
    }
};

export {
    activateUser,
    addUser,
    assignChamber,
    assignRole,
    removeRole,
    blockUser, 
    editUser,
    getAssignedRoles,
    getPermissions,
    getUser, 
    getUsersList
}
