import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { ApiHost } from '../utils/ApiConfig';

const debug = false;

function createEmptyStep1() {

    const step = {
        Data: {
            exportFromPoland: "",
            exportNotFromPoland: "",
            displayName: "",
            applicantNIP: "",
            applicantName: "",
            applicantStreet: "",
            applicantBuilding: "",
            applicantAppartment: "",
            applicantPostCode: "",
            applicantCity: "",
            applicantId: "",
            editExporterCertificateData: false,
            exporterCertificateData: "",
            signedIndependently: false,
            signedByProxy: false,
            signedByAgent: false,
            agentNIP: "",
            agentName: "",
            agentStreet: "",
            agentBuilding: "",
            agentAppartment: "",
            agentPostCode: "",
            agentCity: "",
            agentId: "",
            proxyFiles: [createEmptyFileData()],
            phoneNumber: "",
            exporterIsProducer: false,
            exporterIsNotProducer: false,
            transportDispatched: false,
            transportNotDispatched: false,
            IE599File: createEmptyFileData()
        },
        Comments: []
    }

    return step;
}

function createEmptyProductData() {
    const productData = {
        productName: "",
        quantity: "",
        unit: "",
        quantityDesc: "",
        tariffCode: "",
        originCountry: "",
        producerName: "",
        producerCountry: "",
        producerStreet: "",
        producerBuilding: "",
        producerAppartment: "",
        producerPostCode: "",
        producerCity: "",
        producer: "",
        exporter: "",
        boughtInEU: "",
        boughtOutsideEU: "",
        importClearanceFile: createEmptyFileData()
    };

    return productData;
}

function createEmptySummary() {
    const productsSummary = {
        totalQuantity: "",
        totalNetWeight: "",
        totalGrossWeight: "",
        transportDetails: "",
        productsRemarks: "",
        productsSummaryEdit: "",
        quantitySummaryEdit: "",
        editSummaries: "false"
    };

    return productsSummary;
}

function createEmptyStep2() {

    const step = {
        Data: {
            Products: [createEmptyProductData()],
            Summary: createEmptySummary()
        },
        Comments: []
    }

    return step;
}

function createEmptyProductComponent() {
    const productComponent = {
        componentName: "",
        originCountry: "",
        tariffCode: ""
    };

    return productComponent;
}

function createEmptyProductDetails() {
    const productDetails = {
        name: "",
        Components: [createEmptyProductComponent()]
    };

    return productDetails;
}

function createEmptyProcessData() {
    const processData = {
        productionProcessDescription: "",
        processFiles: [createEmptyFileData()],
        Products: []//[createEmptyProductDetails()]
    };

    return processData;
}

function createEmptyStep3() {

    const step = {
        Data: {
            receiverName: "",
            receiverCountry: "",
            receiverAddress: "",
            Processes: []
        },
        Comments: []
    }

    return step;
}

function createEmptyFileData() {
    const fileData = {
        id: "",
        name: "",
        description: ""
    };

    return fileData;

}

function createEmptyFileConnection() {
    const connectionData = {
        productName: ""
    };

    return connectionData;
}

function createEmptyStep4() {

    const step = {
        Data: {
            exportInvoiceFiles: [createEmptyFileData()],
            exportInvoiceValue: "",
            exportInvoiceCurrency: "",
            goodsFiles: [createEmptyFileData()],
            goodsFilesConnections: [],
            otherGoodsFiles: [createEmptyFileData()],
            productFiles: [createEmptyFileData()],
            paymentFile: createEmptyFileData()
        },
        Comments: []
    }

    return step;
}

function createEmptyStep5() {

    const step = {
        Data: {
            statements: [false, false, false, false, false, false, false],
            remarks: "",
            chamber: "",
            printYes: "",
            additionalPrints: "0",
            shippingMethod: "",
            ShippingAddress: {
                name: "",
                country: "",
                street: "",
                building: "",
                appartment: "",
                postCode: "",
                city: "",
                phoneNumber: ""
            },
            shippingInfo: "",
            shippingDateSent: ""
        },
        Comments: []
    }

    return step;
}

function createEmptyApplication() {
    const app = {
        DisplayName: "",
        Step_1: createEmptyStep1(),
        Step_2: createEmptyStep2(),
        Step_3: createEmptyStep3(),
        Step_4: createEmptyStep4(),
        Step_5: createEmptyStep5(),
        SignedDate: "",
        SignedFileId: "",
        SignedFileName: ""
    }

    return app;
}

const getApplicationContent = async (id, accessToken) => {
    if (id === "00000000-0000-0000-0000-000000000000") return null;
    try {
        if (debug) console.log("Pobieranie danych wniosku [" + id + "]");
        const response = await axios.post(ApiHost + `/application/get`, {
            id: id
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let content = response.data.Application;
            if (debug) console.log("Pobrano dane wniosku\n\n\n[" + JSON.stringify(content) + "]");
            return content;
        }

    } catch (error) {
        console.error("Błąd podczas pobierania danych wniosku: ", error);
    }
};

const getApplicationMetadata = async (id, accessToken) => {
    if (id === "00000000-0000-0000-0000-000000000000") return null;
    try {
        if (debug) console.log("Pobieranie metadanych wniosku [" + id + "]");
        const response = await axios.post(ApiHost + `/application/metadata`, {
            id: id
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log(JSON.stringify(result));
            return result;
        }

    } catch (error) {
        console.error("Błąd podczas pobierania metadanych wniosku: ", error);
    }
};

const getSignedApplication = async (id, accessToken) => {
    if (id === "00000000-0000-0000-0000-000000000000") return null;
    try {
        if (debug) console.log("Pobieranie podpisanego wniosku [" + id + "]");
        const response = await axios.get(ApiHost + `/certificate/signed_application/` + id + '/true', {
            responseType: 'blob',
            headers: { Authorization: `Bearer ${accessToken}` }
        });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `signed_application_${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            if (debug) console.log("Pobrano podpisany wniosek");
        }
    } catch (error) {
        console.error("Błąd podczas pobierania podpisanego wniosku: ", error);
    }
};

const getApplicationComment = async (applicationId, step, commentId, accessToken) => {
    if (applicationId === "00000000-0000-0000-0000-000000000000") return null;
    try {
        if (debug) console.log("Pobieranie komentarza z wniosku [" + applicationId + "]");
        const response = await axios.post(ApiHost + `/application/get_comment`, {
            id: applicationId,
            step: step,
            comment_id: commentId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let content = response.data.Application;
            if (debug) console.log("Pobrano dane komentarza\n" + JSON.stringify(content) + "");
            return content;
        }

    } catch (error) {
        console.error("Błąd podczas pobierania komentarza wniosku: ", error);
    }
};

const getOpenCommentCounts = async (applicationId, accessToken) => {
    if (applicationId === "00000000-0000-0000-0000-000000000000") return [];
    try {
        const response = await axios.post(ApiHost + `/application/get_open_comment_counts`, {
            id: applicationId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let count = response.data.OpenCommentCounts;
            return count;
        }
        return [];
    } catch (error) {
        console.error("Błąd podczas pobierania ilości otwartych komentarzy dla wniosku: ", error);
    }
};

const getOpenCommentsCountForStep = async (applicationId, step, accessToken) => {
    if (applicationId === "00000000-0000-0000-0000-000000000000") return 0;
    try {
        if (debug) console.log("Pobieranie ilości komentarzy dla kroku [" + step + "] wniosku [" + applicationId + "]");
        const response = await axios.post(ApiHost + `/application/get_open_comments_count`, {
            id: applicationId,
            step: step
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let count = response.data.OpenCommentCount;
            return count;
        }
        return 0;
    } catch (error) {
        console.error("Błąd podczas pobierania ilości komentarzy dla kroku wniosku: ", error);
    }
};

const updateApplicationContent = async (id, appContent, userId, accessToken) => {
    try {
        if (debug) console.log("Aktualizacja danych wniosku [" + id + "]");
        const response = await axios.post(ApiHost + `/application/update`, {
            id: id,
            content: appContent
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let newId = response.data.id;
            if (debug) console.log("Zaktualizowano dane wniosku [" + id + "]");
        }

    } catch (error) {
        console.error("Błąd podczas aktualizacji danych wniosku: ", error);
    }
};

const updateMessages = async (applicationId, step, conversation, accessToken) => {
    try {
        if (debug) console.log("Aktualizacja wiadomości wniosku [" + applicationId + "|" + step + "]");
        const response = await axios.post(ApiHost + `/application/updateMessages`, {
            id: applicationId,
            step: step,
            conversation: conversation
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let newId = response.data.id;
            if (debug) console.log("Zaktualizowano wiadomości wniosku [" + applicationId + "]");
        }

    } catch (error) {
        console.error("Błąd podczas aktualizacji wiadomości wniosku: ", error);
    }
};

const getApplicationStatus = async (id, role, accessToken) => {
    if (id === "00000000-0000-0000-0000-000000000000") return "Nieznany";
    try {
        if (debug) console.log("Pobieranie statusu wniosku [" + id + "]");
        const response = await axios.post(ApiHost + `/application/get_status`, {
            id: id,
            role: role
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let status = response.data;
            if (debug) console.log("Status: " + status);
            return status;
        }
        return "Nieznany";
    } catch (error) {
        console.error("Błąd podczas aktualizacji danych wniosku: ", error);
    }
};

const setApplicationStatus = async (id, status, accessToken) => {
    try {
        if (debug) console.log("Ustawianie statusu wniosku [" + id + "]");
        const response = await axios.post(ApiHost + `/application/set_status`, {
            id: id,
            status: status
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("Result: " + result);
            return result;
        }
        return "false";
    } catch (error) {
        console.error("Błąd podczas aktualizacji statusu wniosku: ", error);
    }
};

const assignChamber = async (id, chamberId, accessToken) => {
    try {
        if (debug) console.log("Przypisanie izby do wniosku [" + id + "]");
        const response = await axios.post(ApiHost + `/application/assign_chamber`, {
            id: id,
            chamberid: chamberId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("assignChamber Result: " + result);
            return result;
        }
        return "false";
    } catch (error) {
        console.error("Błąd podczas przypisywania izby do wniosku: ", error);
    }
};

const assignUser = async (id, userId, accessToken) => {
    try {
        if (debug) console.log("Przypisanie użytkownika do wniosku [" + id + "]");
        const response = await axios.post(ApiHost + `/application/assign_user`, {
            id: id,
            userid: userId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("assignUser Result: " + result);
            return result;
        }
        return "false";
    } catch (error) {
        console.error("Błąd podczas przypisywania użytkownika do wniosku: ", error);
    }
};

const assignOldestApplication = async (userId, accessToken) => {
    try {
        if (debug) console.log("Przypisanie najstarszego wniosku z izby użytkownika [" + userId + "]");
        const response = await axios.post(ApiHost + `/application/assign_oldest_application`, {
            user_id: userId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("assignOldestApplication Result: " + result);
            return result;
        }
        return "false";
    } catch (error) {
        console.error("Błąd podczas przypisywania najstarszego wniosku: ", error);
    }
};

const appendSignedApplication = async (id, signedFileId, signedFileName, accessToken) => {
    try {
        if (debug) console.log("Dodawanie podpisanego wniosku [" + id + "]");
        const response = await axios.post(ApiHost + `/application/append_signed_application`, {
            id: id,
            signedFileId: signedFileId,
            signedFileName: signedFileName
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            if (debug) console.log("appendSignedApplication Result: " + result);
            return result;
        }
        return "false";
    } catch (error) {
        console.error("Błąd podczas dodawania podpisanego wniosku: ", error);
    }
};

export { 
    appendSignedApplication,
    assignChamber,
    assignOldestApplication,
    assignUser,
    createEmptyApplication, 
    getApplicationContent, 
    getApplicationComment,
    getOpenCommentsCountForStep,
    getOpenCommentCounts,
    getApplicationMetadata,
    getApplicationStatus,
    setApplicationStatus,
    getSignedApplication,
    updateApplicationContent, 
    updateMessages, 
    createEmptyFileData, 
    createEmptyFileConnection, 
    createEmptyProductData, 
    createEmptyProductComponent, 
    createEmptyProcessData, 
    createEmptyProductDetails, 
    createEmptyStep3, 
    createEmptySummary
};
