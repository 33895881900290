
const operatorCanEdit = (statusId) => {
    const statuses = [];
    //const statuses = [ 2, 3, 6, 9, 12 ];
    return statuses.includes(statusId);
}

const operatorCanRead = (statusId) => {
    return true;
}

const operatorCanRunProcess = (statusId) => {
    const statuses = [ 3, 5, 7, 8, 9, 10, 13, 14, 15, 16, 17, 18 ];
    return statuses.includes(statusId);
}

const clientCanEdit = (statusId) => {
    const statuses = [ 1, 4 ];
    return statuses.includes(statusId);
}

const clientCanRead = (statusId) => {
    return true;
}

const clientCanRunProcess = (statusId) => {
    const statuses = [ 1, 4, 6, 8, 11 ];
    return statuses.includes(statusId);
}


const ReadOnly = ({statusId, operator = false}) => {

    if (!clientCanEdit(statusId) && !operator)
        return (
            <div className="card p-4">
                Występujesz w roli klienta usługi.<br/>
                Twój wniosek jest obecnie obsługiwany przez operatora.
                {
                    clientCanRunProcess(statusId) && (
                        <span>Możesz wykonać czynności na wniosku.</span>
                    )
                }
            </div>
        )

    if (operator && !operatorCanEdit(statusId))
        return (
            <div className="card p-4">
                Występujesz w roli operatora.<br/>
                Wniosek jest obecnie obsługiwany przez klienta.
                {
                    operatorCanRunProcess(statusId) && (
                        <span>Możesz wykonać czynności na wniosku.</span>
                    )
                }
            </div>
        )
}

export default ReadOnly;

export { 
    operatorCanEdit,
    operatorCanRead,
    operatorCanRunProcess,
    clientCanEdit,
    clientCanRead,
    clientCanRunProcess
 };