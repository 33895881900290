import React, { useState, useEffect } from 'react';
import Sidebar from '../components/layout/Sidebar'
import ApplicationList from '../components/common/ApplicationList';
import { useAuth } from '../context/AuthContext';
import CardHeader from '../components/common/CardHeader';

function Applications({paymentMode = false}) {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const { checkPermission } = useAuth();

    const handleResize = () => {
        if (window.innerWidth >= 992) {
            setIsSidebarVisible(true);
        } else {
            setIsSidebarVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        //handleResize(); // Wywołanie przy montowaniu komponentu

        return () => window.removeEventListener('resize', handleResize); // Oczyszczenie przy demontowaniu
    }, []);

    return (
        <main className="d-flex flex-row" style={{ height: '100vh' }}>
            <Sidebar />
            <div className="content">
                <CardHeader text={"Moje wnioski " + (checkPermission("Zmiana przypisania sprawy") ? "(Koordynator)" : (checkPermission("Obsługa wniosków") ? "(Operator)" : ""))} onlyText="true" />
                <ApplicationList mode={checkPermission("Zmiana przypisania sprawy") ? "coordinator" : (checkPermission("Obsługa wniosków") ? "operator" : "user" )} paymentMode={paymentMode} />
            </div>
        </main>
    );
}

export default Applications;
