import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { ApiHost } from '../../utils/ApiConfig';
import { getPagesCount } from '../../services/Certificate';
import LoadingSpinner from './LoadingSpinner';

const CertificatePreview = ({ applicationId, className, mode = "embedded" }) => {
    const { accessToken } = useAuth();
    const [imageUrl, setImageUrl] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [useCache, setUseCache] = useState(true);
    const [trigger, setTrigger] = useState(0);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [noPages, setNoPages] = useState(false);

    const downloadFile = async () => {
        try {
            if (!applicationId)
                throw "Brak identyfikatora wniosku";

            let count = await getPagesCount(applicationId, accessToken);
            setPageCount((count - 1));
            if (count === 0)
                throw "Brak stron do wyświetlenia";

            const response = await axios.get(ApiHost + `/certificate/preview/` + applicationId + '/' + currentPage + '/' + (useCache ? 'True' : 'False'),
                { responseType: 'blob' },
                { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data;
        } catch (error) {
            console.error('Błąd podczas pobierania pliku:', error);
            throw error;
        }
    };

    const handlePrevPage = async () => {
        if (currentPage > 0)
            setCurrentPage(currentPage - 1);
    }

    const handleNextPage = async () => {
        setCurrentPage(currentPage + 1);
    }

    useEffect(() => {
        const loadImage = async () => {
            setIsRefreshing(true);
            setNoPages(false);
            try {
                const blob = await downloadFile();
                const url = URL.createObjectURL(blob);
                setImageUrl(url);
            } catch (error) {
                if (error === "Brak stron do wyświetlenia") {
                    setNoPages(true);
                    setImageUrl(null);
                }
                console.error('Błąd podczas pobierania pliku:', error);
            }
            setIsRefreshing(false);
        };

        loadImage();

        if (!useCache)
            setUseCache(true);

        return () => {
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
        };
    }, [currentPage, trigger, applicationId]);

    return (
        <div className={className}>
            <div>
                {imageUrl ? (
                    <img src={imageUrl} className='img-fluid border border-2 mb-2' alt="Dynamicznie pobrany obraz" />
                ) : (
                    <div className="d-flex align-items-center justify-content-center p-4" role="status">
                        <div>
                            {noPages ? (
                                <div className='d-flex flex-column'>
                                    {(mode === "embedded") ? (
                                        <div>
                                            <div className='d-flex justify-content-center fw-bold'>Nie można wyświetlić podglądu świadectwa</div>
                                            <div>Sprawdź poprawność danych i spróbuj ponownie</div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className='d-flex justify-content-center fw-bold'>Cannot display certificate preview</div>
                                            <div>Check the data and try again</div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <strong>Generowanie podglądu...</strong>
                            )}
                        </div>
                    </div>)
                }
            </div>

            {!noPages && (
                <div className='d-flex justify-content-between w-100'>
                    {(currentPage > 0) ? (
                        <button className="btn btn-outline-primary h2 rounded-2 w9" onClick={handlePrevPage}>
                            {mode === "embedded" ? "Poprzednia strona" : "Previous page"}
                        </button>
                    ) : (
                        <button className="btn btn-outline-primary h2 rounded-2 w9" disabled>
                            {mode === "embedded" ? "Poprzednia strona" : "Previous page"}
                        </button>
                    )}

                    {!isRefreshing ? (
                        <button className="btn btn-outline-primary h2 rounded-2 w11" onClick={() => { setUseCache(false); setTrigger(prevTrigger => prevTrigger + 1); console.log("trigger: " + (trigger + 1)); }}>
                            {mode === "embedded" ? "Odśwież" : "Refresh"}
                        </button>
                    ) : (
                        <div className="btn btn-outline-primary h2 rounded-2 w11">
                            {mode === "embedded" ? <LoadingSpinner /> : <LoadingSpinner text='Loading data' />}
                        </div>
                    )}

                    {(currentPage < pageCount) ? (
                        <button type="submit" className="btn btn-outline-primary h2 rounded-2 w9" onClick={handleNextPage}>
                            {mode === "embedded" ? "Następna strona" : "Next page"}
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-outline-primary h2 rounded-2 w9" disabled>
                            {mode === "embedded" ? "Następna strona" : "Next page"}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default CertificatePreview;
