import React, { useState, useEffect } from 'react';
import HelpTooltip from './HelpTooltip';
import ValidationInfo from './ValidationInfo';

function CountrySelector({ name, data, onDataChange, required, label, tooltip, validateTrigger, allowUnknown = false, onValidate = undefined }) {

    const [countries, setCountries] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    const [valid, setValid] = useState(true);

    const handleInput = (e) => {
        e.target.name = name;
        setSelectedValue(e.target.value);
        validate(e);
        onDataChange(e);
    }

    const validate = (e) => {
        if (required !== "true")
            setValid(true);
        else{
            setValid((e.target.value.length >= 2));
            if (onValidate)
                onValidate({target: {name: name, value: e.target.value}});
        }
    };

    useEffect(() => {
        if (validateTrigger > 0){
            validate({target: {value: (selectedValue ? selectedValue : data)}});
        }
    }, [data, validateTrigger]);

    useEffect(() => {
        // Ładowanie danych państw
        fetch('/countries.json') // Ścieżka do pliku JSON
            .then(response => response.json())
            .then(data => {
                // Sortowanie alfabetyczne po nazwie
                const sortedCountries = data.sort((a, b) => a.name.localeCompare(b.name));
                if (allowUnknown)
                    sortedCountries.push({ code: 'XX', name: 'Nieznany' });
                setCountries(sortedCountries);
            })
            .catch(error => {
                console.error('Błąd podczas ładowania listy państw: ', error);
            });
        setSelectedValue(data);
    }, []); 

    return (
        <div>
            <div className='d-flex flex-row w-100'>
                <label className="form-label">{label + ((required === "true") ? " *" : "")}</label>
                {tooltip && (
                    <HelpTooltip title={tooltip} />
                )}
                {(!valid) && (
                    <ValidationInfo title="Wypełnienie pola jest obowiązkowe. Wybierz wartość z listy." visible={true} />
                )}
            </div>
            <select
                className="form-control form-select my-0 h2 rounded-3" 
                name="countrySelect" 
                value={data} 
                onChange={handleInput} 
                onBlur={validate} 
                required={required === "true"} 
                invalid={valid ? "false" : "true"}
            >
                <option value="">Wybierz ...</option>
                {countries.map(country => (
                    <option key={country.code} value={country.code}>
                        {country.name} ({country.code})
                    </option>
                ))}
            </select>
        </div>
    );
}

export default CountrySelector;
