import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import CommunicationPanel from './CommunicationPanel';
import OperationsPanel from './OperationsPanel';
import ProcessPanel from './ProcessPanel';

function OperatorsPanel({ isVisible, conversationId, data, trigger, communicationCallbacks, showProcess = false, modeChangeHandler, initialDisplayMode }) {

    const [communicationPanelVisible, setCommunicationPanelVisible] = useState(false);
    const [operationsPanelVisible, setOperationsPanelVisible] = useState(false);
    const [processPanelVisible, setProcessPanelVisible] = useState(false);
    const [displayMode, setDisplayMode] = useState(initialDisplayMode);
    const developerMode = false;

    const debug = false;

    const showCommunicationPanel = () => {
        hideAllPanels();
        setCommunicationPanelVisible(true);
    }

    const showOperationsPanel = () => {
        hideAllPanels();
        setOperationsPanelVisible(true);
    }

    const showProcessPanel = () => {
        hideAllPanels();
        setProcessPanelVisible(true);
    }

    const hideAllPanels = () => {
        setCommunicationPanelVisible(false);
        setOperationsPanelVisible(false);
        setProcessPanelVisible(false);
    }

    const handleDisplayMode = (mode) => {
        setDisplayMode(mode);
        modeChangeHandler(mode);
    }

    useEffect(() => {
        if (conversationId) {
            showCommunicationPanel();
            if (debug) console.log("uE - CP Reload")
        }
        else if (showProcess) {
            showProcessPanel();
        }
    }, [trigger, conversationId]);

    if (!isVisible)
        return null;

    return (
        <div className='d-flex flex-row'>
            {(communicationPanelVisible || operationsPanelVisible || processPanelVisible) ? (
                <div className='ms-0 d-flex flex-row w23'>
                    <div className='d-flex flex-column ms-auto'>
                        <ProcessPanel isVisible={processPanelVisible} />
                        <CommunicationPanel isVisible={communicationPanelVisible} data={data} communicationCallbacks={communicationCallbacks} />
                        <OperationsPanel isVisible={operationsPanelVisible} />
                    </div>

                    <div>
                        <div className='d-flex flex-column p-0 mt-2 rounded-start-3 shadow'>
                            <div className={'tooltip text-black sidepanel-nav ' + (communicationPanelVisible && 'sidepanel-nav-selected')} onClick={communicationPanelVisible ? hideAllPanels : showCommunicationPanel}>
                                <i className='bi-envelope' style={{ fontSize: "30px" }}></i>
                                <span className='tooltiptext tooltip-left'>Otwórz panel komunikacji</span>
                            </div>

                            {/* {developerMode && (
                                <div className={'sidepanel-nav ' + (operationsPanelVisible && 'sidepanel-nav-selected')} onClick={operationsPanelVisible ? hideAllPanels : showOperationsPanel} selected="true" title="Otwórz panel właściwości">
                                    <i className='bi-clipboard2-check' style={{ fontSize: "30px" }}></i>
                                </div>
                            )} */}

                            {/* <div className={'sidepanel-nav ' + (processPanelVisible && 'sidepanel-nav-selected')} onClick={processPanelVisible ? hideAllPanels : showProcessPanel} title="Otwórz panel zarządzania procesem">
                                <i className='bi-list-check' style={{ fontSize: "30px" }}></i>
                            </div> */}

                            {/* <div className={'sidepanel-nav ' + ((displayMode === 0) && 'sidepanel-nav-selected')} onClick={() => handleDisplayMode(0)}>
                                <i className='bi-input-cursor-text' style={{ fontSize: "30px" }}></i>
                            </div>
                            */}
                            <div className={'tooltip text-black sidepanel-nav ' + ((displayMode === 1) && 'sidepanel-nav-selected')} onClick={() => handleDisplayMode(1)}>
                                <i className='bi-code-square' style={{ fontSize: "30px" }}></i>
                                <span className='tooltiptext tooltip-left'>Przejdź do widoku sprawy</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='d-flex flex-column p-0 mt-2 rounded-start-3 shadow'>
                    <div className='sidepanel-nav tooltip text-black' onClick={showCommunicationPanel}>
                        <i className='bi-envelope' style={{ fontSize: "30px" }}></i>
                        <span className='tooltiptext tooltip-left'>Otwórz panel komunikacji</span>
                    </div>

                    {/* {developerMode && (
                        <div className='sidepanel-nav' onClick={showOperationsPanel}>
                            <i className='bi-clipboard2-check' style={{ fontSize: "30px" }}></i>
                        </div>
                    )} */}

                    <div className='sidepanel-nav tooltip text-black' onClick={showProcessPanel}>
                        <i className='bi-list-check' style={{ fontSize: "30px" }}></i>
                        <span className='tooltiptext tooltip-left'>Otwórz panel obsługi wniosku</span>
                    </div>

                    <div className={'sidepanel-nav tooltip text-black ' + ((displayMode === 1) && 'sidepanel-nav-selected')} onClick={() => handleDisplayMode(1)}>
                        <i className='bi-code-square' style={{ fontSize: "30px" }}></i>
                        <span className='tooltiptext tooltip-left'>Przejdź do widoku sprawy</span>
                    </div>
                </div>
            )}

        </div>
    );
}

export default OperatorsPanel;
