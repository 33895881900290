import React from 'react';
import PropTypes from 'prop-types';
import CardHeader from './CardHeader';

const CaseLog = ({ logData }) => {
    if (!logData)
        return null;
    
    return (
        <div className='w-100'>
            <CardHeader text="Dziennik zdarzeń" onlyText="true" />
            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column'>
                    <div className="case-log">
                        {logData.Log && (
                            <div>
                            <h4>Zdarzenia</h4>
                            <div className='d-flex flex-row border-bottom mt-1'>
                                <strong className='w9'>
                                    Data
                                </strong>
                                <strong className='w5 ms-1'>
                                    Typ
                                </strong>
                                <strong className='w23 ms-1'>
                                    Wiadomość
                                </strong>
                                <strong className='w7 ms-1'>
                                    Użytkownik
                                </strong>
                            </div>
                            {logData.Log.map((entry, index) => (
                                <div className={'d-flex flex-row ' + (((index % 2) === 1) ? '' : 'bg-light bg-opacity-50')} key={index}>
                                    <div className='w9'>
                                        {new Date(entry.date).toLocaleString()}
                                    </div>
                                    <div className='w5 ms-1'>
                                        {entry.type === "info" && (
                                            <i className='bi-info-circle-fill text-info'></i>
                                        )}
                                        {entry.type === "warning" && (
                                            <i className='bi-exclamation-triangle-fill text-warning'></i>
                                        )}
                                        {entry.type === "error" && (
                                            <i className='bi-x-circle-fill text-danger'></i>
                                        )}
                                    </div>
                                    <div className='w23 ms-1'>
                                        {entry.message}
                                    </div>
                                    <div className='w7 ms-1'>
                                        {entry.user || 'N/A'}
                                    </div>
                                </div>
                            ))}
                        </div>
                        )}
                        {logData.UserInput && (
                            <div className='mt-2'>
                            <h4>Wartości wprowadzone przez użytkownika</h4>
                            <div className='d-flex flex-row border-bottom mt-1'>
                                <strong className='w9'>
                                    Data
                                </strong>
                                <strong className='w5 ms-1'>
                                    Krok
                                </strong>
                                <strong className='w23 ms-1'>
                                    Wartość
                                </strong>
                                <strong className='w7 ms-1'>
                                    Użytkownik
                                </strong>
                            </div>
                            {logData.UserInput.map((input, index) => (
                                <div className={'d-flex flex-row ' + (((index % 2) === 1) ? '' : 'bg-light bg-opacity-50')} key={index}>
                                    <div className='w9'>
                                        {new Date(input.date).toLocaleString()}
                                    </div>
                                    <div className='w5 ms-1'>
                                        {input.stepId}
                                    </div>
                                    <div className='w23 ms-1'>
                                        {input.value}
                                    </div>
                                    <div className='w7 ms-1'>
                                        {input.user || 'N/A'}
                                    </div>
                                </div>
                            ))}
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

CaseLog.propTypes = {
    logData: PropTypes.shape({
        Log: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
                message: PropTypes.string.isRequired,
                user: PropTypes.string,
            })
        ).isRequired,
        UserInput: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.string.isRequired,
                stepId: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                user: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

export default CaseLog;