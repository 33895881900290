
// Id punktu płatności (pos_id):	486179
// Drugi klucz (MD5):	1ca0b5ec3f3394a49fa9461335ce62a6
// Protokół OAuth - client_id :	486179
// Protokół OAuth - client_secret:	e6befc1e1fa9626bed6fe9e011e07b40

const pos_id = '486179';
const client_id	= '486179';
const MD5 = '1ca0b5ec3f3394a49fa9461335ce62a6';
const client_secret = 'e6befc1e1fa9626bed6fe9e011e07b40';
const sandbox = 'https://secure.snd.payu.com';
const production = 'https://secure.payu.com';
const authorize = '/pl/standard/user/oauth/authorize';
const server = sandbox; 

//production = sandbox;

// '/pl/standard/user/oauth/authorize \
//  -d 'grant_type=client_credentials' \
//  -d 'client_id=460718' \
//  -d 'client_secret=22f4175da9f0f72bcce976dd8bd7504f'
// const redirect_url = 'http://localhost:3000/payment';
// const return_url = 'http://localhost:3000/payment';
// const cancel_url = 'http://localhost:3000/payment';
// const notify_url = 'http://localhost:3000/payment';
// const currency = 'PLN';
// const language = 'pl';



export { 
    pos_id, 
    client_id, 
    MD5, 
    client_secret,
    server,
    authorize
};