import axios from 'axios';
import { server, client_id, client_secret, authorize } from '../utils/PaymentConfig';

const Payment = {
    authorize: async () => {
        const url = `https://secure.snd.payu.com/pl/standard/user/oauth/authorize`;
        // const data = {
        //     grant_type: 'client_credentials',
        //     client_id: 486179,
        //     client_secret: 'e6befc1e1fa9626bed6fe9e011e07b40'
        // };
        const data = new URLSearchParams();
        data.append('grant_type', 'client_credentials');
        data.append('client_id', 486179);
        data.append('client_secret', 'e6befc1e1fa9626bed6fe9e011e07b40');

            // grant_type: 'client_credentials',
            // client_id: 486179,
            // client_secret: client_secret
        //};
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return response.data;
    },

    createOrder: async (accessToken, orderData) => {
        const url = `${server}/api/v2_1/orders`;
        const response = await axios.post(url, orderData, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    }
};

export default Payment;