import React from 'react';

function Step({ number = 1, title = "Step title ABCDE", warning = 0, active = false, markCompleted = false, onclick, applicationMode = true }) {
    return (
        <div className={'position-relative ' + (applicationMode && 'text-white bg-secondary hand') + ' p-0'} style={{ width: '140px' }} onClick={onclick}>
            <div className="position-absolute" style={{ top: '0px', right: '40px' }}>
                {warning > 0 && (<div className='rounded-circle p-0' style={{ backgroundColor: '#cc0000', width: '14px', height: '14px' }} />)}
            </div>
            <div className='d-flex flex-column align-items-center'>
                <div 
                className={'rounded-circle border border-3 shadow p-0 mb-1 d-flex justify-content-center align-items-center fw-semibold ' + ((!applicationMode && markCompleted ) && ' text-white bg-success border-success ') + ((!applicationMode && !markCompleted && active ) && ' text-secondary border-secondary ') + ((!applicationMode && !markCompleted && !active ) && ' text-secondary text-opacity-25 border-secondary border-opacity-25')}
                style={{ ...((active && applicationMode )? { backgroundColor: 'white', color: 'black' } : {}), width: '50px', height: '50px', borderWidth: '3px', borderColor: 'gray' }}>
                    {markCompleted ? (
                        <div className='fs-2 align-self-center'><i className='bi-check-lg' /></div>
                    ) : (
                        <div className='fs-2 align-self-center'>{number}</div>
                    )}
                </div>
                <div style={{ textAlign: 'center', fontSize: '12px' }}>{title}</div>
            </div>

        </div>
    );
}

export default Step;