import React, { useEffect, useState } from 'react';
import HelpTooltip from './HelpTooltip';
import CountrySelector from './CountrySelector';
import { validateBusinessName, validatePostCode, validateAppartment, validateBuilding, validateCity, validateStreet, validateCountryCode } from '../../utils/Validators';
import { createEmptyFileData } from '../../services/Application';
import FileUploadComponent from './FileUploadComponent';
import ValidationInfo from './ValidationInfo';

const tooltips = [
    "Może różnić się od kraju pochodzenia, jeżeli dokonujesz ostatecznego montażu, konfekcjonowania, wprowadzasz do obrotu",
    "Jeżeli jesteś producentem towaru i wybierzesz opcję <strong>\"Pobierz z danych firmy\"</strong>, uzupełnią się one automatycznie. Możesz je zmienić - na przykład wskazać adres oddziału przedsiębiorstwa.",
    "Określ miejsce zakupu towaru.",
    "Adres zostanie uzupełniony danymi adresowymi wnioskodawcy."
];

const debug = false;

function ProducerAddress({ index, data, isProducer, shippingAddress = false, fromOutsideEU, applicant, onDataChange, onValidate = undefined }) {
    const [validateTrigger, setValidateTrigger] = useState(0);

    const handleProducerNameInput = (e) => {
        onDataChange(e);
    }

    function handleProducerCountryInput(e) {
        onDataChange(e);
    }

    function handleProducerStreetInput(e) {
        onDataChange(e);
    }

    function handleProducerBuildingInput(e) {
        onDataChange(e);
    }

    function handleProducerAppartmentInput(e) {
        onDataChange(e);
    }

    function handleProducerCityInput(e) {
        onDataChange(e);
    }

    function handleProducerPostCodeInput(e) {
        if (producerCountry === "PL") {
            if (e.target.value.length > 6) {
                e.target.value = e.target.value.slice(0, 6);
            }
            else {
                let value = e.target.value.replace(/[^0-9]/g, ''); // Usunięcie wszystkich znaków, które nie są cyframi
                if (value.length > 2) {
                    // Wstawienie kreski po dwóch pierwszych cyfrach
                    value = value.slice(0, 2) + '-' + value.slice(2);
                }
                e.target.value = value;
            }
        }
        else {
            if (e.target.value.length > 11)
                e.target.value = e.target.value.slice(0, 11);
        }
        onDataChange(e);
    }

    /**
     * Handles the click event for importing data from business data.
     */
    const handleImportFromBusinessDataClick = async () => {
        const businessData = applicant;// 
        data = businessData;
        const updateImportData = async () => {
            Object.entries(businessData).forEach(([key, entryValue]) => {
                let e = { target: { name: key, value: entryValue } };
                onDataChange(e);
            });
        }
        await updateImportData();
        setValidateTrigger(validateTrigger + 1);
    }

    if (!data) {
        data = {}
    }
    const { producerName, producerCountry, producerStreet, producerBuilding, producerAppartment, producerPostCode, producerCity, boughtInEU, boughtOutsideEU, importClearanceFile } = data;
    const [validators, setValidators] = useState({ producerName: "", producerCountry: "", producerStreet: "", producerBuilding: "", producerAppartment: "", producerPostCode: "", producerCity: "", boughtInEU: "", boughtOutsideEU: "", importClearanceFile: "" });

    /**
     * Handles the file change event.
     *
     * @param {string} id - The ID of the file.
     * @param {string} name - The name of the file.
     * @param {string} description - The description of the file.
     * @param {string} fileType - The type of the file.
     * @param {number} index - The index of the file.
     */
    const handleFileChange = (id, name, description, fileType, index) => {
        const fileInfo = createEmptyFileData();
        fileInfo.id = id;
        fileInfo.name = name;
        fileInfo.description = description;
        //const file = { target: { name: fileType, value: { id: id, name: name, description: description } } }

        let e = { target: { name: fileType, value: fileInfo } };
        onDataChange(e);
    }

    const handleFileRemove = (fileType) => {
        const fileInfo = createEmptyFileData();

        let e = { target: { name: fileType, value: fileInfo } };
        onDataChange(e);
    }

    const validateAll = () => {
        setValidateTrigger(validateTrigger + 1);
        let newValidators = { ...validators };
        newValidators.producerName = !validateBusinessName(data.producerName, isProducer);
        newValidators.producerStreet = !validateStreet(data.producerStreet, isProducer);
        newValidators.producerBuilding = !validateBuilding(data.producerBuilding, isProducer);
        newValidators.producerAppartment = !validateAppartment(data.producerAppartment);
        newValidators.producerPostCode = !validatePostCode(data.producerPostCode, isProducer, (producerCountry !== "PL"));
        newValidators.producerCity = !validateCity(data.producerCity, isProducer);
        setValidators(newValidators);
    }

    const validate = (e) => {
        const { name, value } = e.target;
        let newValidators = { ...validators };
        switch (name) {
            case "producerName":
                newValidators.producerName = !validateBusinessName(value, isProducer);
                break;
            case "producerStreet":
                newValidators.producerStreet = !validateStreet(value, isProducer);
                break;
            case "producerBuilding":
                newValidators.producerBuilding = !validateBuilding(value, isProducer);
                break;
            case "producerAppartment":
                newValidators.producerAppartment = !validateAppartment(value);
                break;
            case "producerPostCode":
                newValidators.producerPostCode = !validatePostCode(value, isProducer, (producerCountry !== "PL"));
                break;
            case "producerCity":
                newValidators.producerCity = !validateCity(value, isProducer);
                break;
            case "producerCountry":
                newValidators.producerCountry = !validateCountryCode(value);
            default:
                break;
        }
        setValidators(newValidators);
    };

    useEffect(() => {
        if (shippingAddress) {  // Jeżeli jest to adres odbiorcy
            const updatedData = { ...data, producerCountry: "PL" };
            onDataChange({ target: { name: "producerCountry", value: "PL" } });
            //onDataChange({ target: { name: "data", value: updatedData } });
        }
    }, [shippingAddress]);

    useEffect(() => {
        if (debug) console.log("ProducerAddress: useEffect(data)");
        if (debug) console.log(JSON.stringify(data));
    }, [data]);

    useEffect(() => {
        if (validateTrigger > 0)
            validateAll();
    }, [isProducer, validateTrigger]);

    useEffect(() => {
        const falseValues = ["", "false", null, false];
//        let value = (validators.producerName || validators.producerStreet || validators.producerBuilding || validators.producerAppartment || validators.producerPostCode || validators.producerCity) ? "false" : "true";
        let value = ((falseValues.includes(validators.producerName)
                    && falseValues.includes(validators.producerStreet)
                    && falseValues.includes(validators.producerBuilding)
                    && falseValues.includes(validators.producerAppartment)
                    && falseValues.includes(validators.producerPostCode)
                    && falseValues.includes(validators.producerCity)
                    && falseValues.includes(validators.producerCountry))
                    && (!((!isProducer) && (boughtInEU !== "true" && boughtOutsideEU !== "true")))
                ) ? "false" : "true";
        if (debug) console.log("ProducerAddress: onValidate({ target: { name: address, value: " + value + " } })");
        if (debug) console.log("ProducerAddress: prod = " + ((!isProducer) && (boughtInEU !== "true" && boughtOutsideEU !== "true")));
        if (onValidate)
            onValidate({ target: { name: "address", value: value } });
    }, [validators]);

    return (
        <div className='d-flex flex-column'>
            {isProducer && (
                <div className='mb-1'>
                    <div className='d-flex flex-row'>
                        <label className="form-label">Pobierz z wprowadzonych wcześniej danych firmy</label>
                        {(tooltips[1] && !shippingAddress) && (
                            <HelpTooltip title={tooltips[1]} />)}
                        {shippingAddress && (
                            <HelpTooltip title={tooltips[3]} />
                        )} 
                    </div>
                    <button
                        type="button"
                        className={"btn btn-outline-primary h2 rounded-2 ms-auto " + (shippingAddress ? 'w13' : 'w15')}
                        onClick={handleImportFromBusinessDataClick}>
                            Pobierz z danych firmy
                    </button>
                </div>
            )}

            <div className='d-flex flex-row'>
                <div className={shippingAddress ? 'w33' : 'w35'}>
                    <div className='d-flex flex-row'>
                        <label className="form-label">Nazwa {shippingAddress ? "odbiorcy" : "producenta"}{isProducer && (" *")}</label>
                        <ValidationInfo title={((isProducer) ? "Wypełnienie pola jest obowiązkowe." : "")} visible={validators.producerName ? "true" : "false"} />
                    </div>
                    <input
                        type="text"
                        className={"form-control my-0 h2 rounded-3 " + (shippingAddress ? 'w33' : 'w35')}
                        maxLength="160"
                        name="producerName"
                        value={producerName}
                        onChange={handleProducerNameInput}
                        placeholder={"Nazwa " + (shippingAddress ? "odbiorcy" : "producenta")}
                        onBlur={validate}
                        required={isProducer}
                        invalid={validators.producerName && "true"}
                    />
                </div>

                {!shippingAddress && (
                <div className='ms-1 w11'>
                    <CountrySelector
                        name="producerCountry"
                        data={producerCountry}
                        label="Kraj produkcji"
                        tooltip={tooltips[0]}
                        required="true"
                        onDataChange={handleProducerCountryInput}
                        onBlurHandler={validate}
                        validateTrigger={validateTrigger}
                        onValidate={validate}
                    />
                </div>
                )}
            </div>

            <div className='d-flex flex-row mt-1'>
                <div className='w15'>
                    <div className='d-flex flex-row'>
                        <label className="form-label">Ulica{isProducer && (" *")}</label>
                        <ValidationInfo title={((isProducer) ? "Wypełnienie pola jest obowiązkowe." : "")} visible={validators.producerStreet ? "true" : "false"} />
                    </div>
                    <input
                        type="text"
                        className={"form-control my-0 h2 rounded-3 " + (shippingAddress ? 'w13' : 'w15')}
                        maxLength="66"
                        name="producerStreet"
                        value={producerStreet}
                        onChange={handleProducerStreetInput}
                        placeholder="Ulica"
                        onBlur={validate}
                        required={isProducer}
                        invalid={validators.producerStreet && "true"}
                    />
                </div>

                <div className='ms-1 w5'>
                    <div className='d-flex flex-row'>
                        <label className="form-label">Nr domu{isProducer && (" *")}</label>
                        <ValidationInfo title={((isProducer) ? "Wypełnienie pola jest obowiązkowe." : "")} visible={validators.producerBuilding ? "true" : "false"} />
                    </div>
                    <input
                        type="text"
                        className="form-control my-0 w5 h2 rounded-3"
                        maxLength="9"
                        name="producerBuilding"
                        value={producerBuilding}
                        onChange={handleProducerBuildingInput}
                        placeholder="Nr domu"
                        onBlur={validate}
                        required={isProducer}
                        invalid={validators.producerBuilding && "true"}
                    />
                </div>

                <div className='ms-1 w5'>
                    <div className='d-flex flex-row'>
                        <label className="form-label">Lokal</label>
                        <ValidationInfo title={((isProducer) ? "Wprowadź poprawną wartość - maksymalnie 11 znaków, cyfry, litery i znaki:<br/><strong>- , \\ / .</strong>" : "")} visible={validators.producerAppartment ? "true" : "false"} />
                    </div>
                    <input
                        type="text"
                        className="form-control my-0 w5 h2 rounded-3"
                        maxLength="11"
                        name="producerAppartment"
                        value={producerAppartment}
                        onChange={handleProducerAppartmentInput}
                        placeholder="Lokal"
                        onBlur={validate}
                        required={false}
                        invalid={validators.producerAppartment && "true"}
                    />
                </div>

                <div className='ms-1 w7'>
                    <div className='d-flex flex-row'>
                        <label className="form-label">Kod pocztowy{isProducer && (" *")}</label>
                        <ValidationInfo title={((isProducer) ? "Wypełnienie pola jest obowiązkowe." : "")} visible={validators.producerPostCode ? "true" : "false"} />
                    </div>
                    <input
                        type="text"
                        className="form-control my-0 w7 h2 rounded-3"
                        maxLength="14"
                        name="producerPostCode"
                        value={producerPostCode}
                        onChange={handleProducerPostCodeInput}
                        placeholder="Kod pocztowy"
                        onBlur={validate}
                        required={isProducer}
                        invalid={validators.producerPostCode && "true"}
                    />
                </div>

                <div className='ms-1 w11'>
                    <div className='d-flex flex-row'>
                        <label className="form-label">Miejscowość{isProducer && (" *")}</label>
                        <ValidationInfo title={((isProducer) ? "Wypełnienie pola jest obowiązkowe." : "")} visible={validators.producerCity ? "true" : "false"} />
                    </div>
                    <input
                        type="text"
                        className="form-control my-0 w11 h2 rounded-3"
                        maxLength="30"
                        name="producerCity"
                        value={producerCity}
                        onChange={handleProducerCityInput}
                        placeholder="Miejscowość"
                        onBlur={validate}
                        required={isProducer}
                        invalid={validators.producerCity && "true"}
                    />
                </div>
            </div>

            {!isProducer && (
                <div className='d-flex flex-column mt-2 pt-1 border-top'>
                    <div className='d-flex flex-row'>
                        <label className="form-label mb-1">Czy towar został zakupiony na terenie Unii Europejskiej? *</label>
                        {tooltips[2] && (
                            <HelpTooltip title={tooltips[2]} />)}
                        <ValidationInfo title="Wypełnienie pola jest obowiązkowe." visible={(boughtInEU !== "true" && boughtOutsideEU !== "true") ? "true" : "false"} />

                    </div>

                    <div className='d-flex flex-column'>
                        <div className="form-check form-check-inline mb-1">
                            <input className="form-check-input" type="radio" name={"boughtInEU_" + index} id={"boughtInEU_" + index} checked={(boughtInEU === "true")} onChange={onDataChange} />
                            <label className="form-check-label" htmlFor={"boughtInEU_" + index}>TAK</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name={"boughtOutsideEU_" + index} id={"boughtOutsideEU_" + index} checked={(boughtOutsideEU === "true")} onChange={onDataChange} />
                            <label className="form-check-label" htmlFor={"boughtOutsideEU_" + index}>NIE</label>
                        </div>
                    </div>
                </div>
            )}
            {(boughtOutsideEU === "true") && (
                <div className='d-flex flex-row my-1'>
                    <div className='d-flex flex-row'>
                        <label className="form-label">Dodaj dokument potwierdzający odprawę importową*</label>
                        <ValidationInfo title="Wypełnienie pola jest obowiązkowe." visible={(boughtOutsideEU === "true" && (!importClearanceFile.id)) ? "true" : "false"} />
                    </div>
                </div>
            )}
            {(boughtOutsideEU === "true") && (
                <div className='d-flex flex-fill w-100'>
                    {importClearanceFile && (
                        <FileUploadComponent index={0} fileId={importClearanceFile.id} fileName={importClearanceFile.name} fileDescription={importClearanceFile.description} fileType="importClearanceFile" onChange={handleFileChange} onRemove={() => handleFileRemove("importClearanceFile")} />
                    )}

                    {/* <button type="button" className="btn btn-outline-primary py-2 rounded-2 me-auto mb-4" style={{ width: '340px' }} onClick={handleImportFromBusinessDataClick}>Dodaj plik</button> */}
                </div>
            )}
        </div>
    );
}

export default ProducerAddress;
