import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { assignChamber, assignUser, getApplicationMetadata, getApplicationStatus, setApplicationStatus } from '../../services/Application';
import { getChambers, getStatuses, getUsers } from '../../services/Dictionary';
import { useAuth } from '../../context/AuthContext';

function OperationsPanel({ isVisible }) {

    let { step, id } = useParams();
    const [displayName, setDisplayName] = useState();
    const [applicantName, setApplicantName] = useState();
    const [metadata, setMetadata] = useState();
    const [status, setStatus] = useState();
    const [statusFront, setStatusFront] = useState();
    const [statuses, setStatuses] = useState();
    const [chamber, setChamber] = useState();
    const [chamberName, setChamberName] = useState();
    const [chambers, setChambers] = useState();
    const [user, setUser] = useState();
    const [userName, setUserName] = useState("brak");
    const [users, setUsers] = useState();
    const { checkPermission } = useAuth();

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    }

    const handleChamberChange = (e) => {
        setChamber(e.target.value);
    }

    const handleUserChange = (e) => {
        setUser(e.target.value);
    }

    const handleSave = () => {
        setApplicationStatus(id, status);
        assignChamber(id, chamber);
        console.log("user: " + user);
        if (user !== ""){
            console.log("Przypisanie użytkownika");
            assignUser(id, user);}

    };

    useEffect(() => {
        const fetchData = async () => {
            // let stat = await getApplicationStatus(id, "operator");
            // let statFront = await getApplicationStatus(id, "applicant");
            // setStatus(stat);
            // setStatusFront(statFront);

            let tempMetadata = await getApplicationMetadata(id);
            setMetadata(tempMetadata);

            setDisplayName(tempMetadata.displayName);

            let tempStat = await getStatuses();
            setStatuses(tempStat);

            setStatus(tempMetadata.statusNameInternal);
            setStatusFront(tempMetadata.statusName);

            let tempChambers = await getChambers();
            setChambers(tempChambers);
            if (tempMetadata.Chamber){
                setChamber(tempMetadata.Chamber.id);
                setChamberName(tempMetadata.Chamber.name);
            }

            if (tempMetadata.Operator){
                setUser(tempMetadata.Operator.id);
                setUserName(tempMetadata.Operator.lastName + " " + tempMetadata.Operator.firstName);
            }

            if (tempMetadata.Applicant){
                setApplicantName(tempMetadata.Applicant.lastName + " " + tempMetadata.Applicant.firstName);
            }

            if (tempMetadata.Chamber){
                let tempUsers = await getUsers(tempMetadata.Chamber.id);
                setUsers(tempUsers);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (chamber) {
                let tempUsers = await getUsers(chamber);
                setUsers(tempUsers);
            }
        };
        fetchData();
    }, [chamber]);


    if (isVisible)
        return (
            <div className='d-flex flex-column me-1' style={{ width: "300px" }}>
                <h3>WŁAŚCIWOŚCI</h3>

                {displayName && (
                    <div className='mb-1'>
                        <label className="form-label">Nazwa robocza</label>
                        <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}>{displayName}</div>
                    </div>
                )}

                {applicantName && (
                    <div className='mb-1'>
                        <label className="form-label">Wnioskodawca</label>
                        <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}>{applicantName}</div>
                    </div>
                )}

                {status && (
                    <div className='mb-1'>
                        <label className="form-label">Status wniosku klienta</label>
                        <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}>{statusFront}</div>
                    </div>
                )}

                {checkPermission("Obsługa wniosków") && (
                <div className='mb-1'>
                    <div className='d-flex flex-row w-100'>
                        <label className="form-label">Status wniosku (wewnętrzny)</label>
                    </div>

                    <select className="form-control form-select my-0 h2 rounded-3" style={{ background: "white" }} name={"statusSelector"} value={status} onChange={handleStatusChange} >
                        <option value="">Wybierz ...</option>
                        {statuses && statuses.map((status, index) => (
                            <option key={index} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                </div>
                )}

                <div className='mb-1'>
                    <div className='d-flex flex-row w-100'>
                        <label className="form-label">Izba</label>
                    </div>

                    {checkPermission("Zmiana przypisania sprawy") ? (
                        <select className="form-control form-select my-0 h2 rounded-3" style={{ background: "white" }} name={"chamberSelector"} value={chamber} onChange={handleChamberChange} >
                        <option value="">Wybierz ...</option>
                        {chambers && chambers.map(c => (
                            <option key={c.Id} value={c.Id}>
                                {c.Name} ({c.City})
                            </option>
                        ))}
                        </select>) : (
                        <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}>{chamberName}</div>                    
                        )}
                </div>

                <div className='mb-1'>
                    <div className='d-flex flex-row w-100'>
                        <label className="form-label">Operator</label>
                    </div>

                    {checkPermission("Zmiana przypisania sprawy") ? (
                    <select className="form-control form-select my-0 h2 rounded-3" style={{ background: "white" }} name={"userSelector"} value={user} onChange={handleUserChange} >
                        <option value="">Wybierz ...</option>
                        {users && users.map(u => (
                            <option key={u.Id} value={u.Id}>
                                {u.Name}
                            </option>
                        ))}
                    </select>) : (
                        <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}>{userName}</div>                    
                        )}
                </div>

                <div className='d-flex flex-row'>
                    <button className='btn btn-primary ms-auto' style={{width: "120px"}} onClick={handleSave}>
                        Zapisz
                    </button>
                </div>
            </div>
        );
}

export default OperationsPanel;
