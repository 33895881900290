import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getApplicationMetadata } from '../../services/Application';
import { addInputToStep, commitStep, getStep } from '../../services/Process';
import { useAuth } from '../../context/AuthContext';
import LoadingSpinner from './LoadingSpinner';
import { operatorCanRunProcess, clientCanRunProcess } from './ReadOnly';

function ProcessPanel({ embedded = false }) {

    let { step, id } = useParams();
    const { checkPermission, accessToken, userName } = useAuth();
    const [stepDescription, setStepDescription] = useState();
    const [stepDestinations, setStepDestinations] = useState();
    const [selectedValue, setSelectedValue] = useState();
    const [displayName, setDisplayName] = useState();
    const [statusId, setStatusId] = useState();
    const [status, setStatus] = useState();
    const [statusFront, setStatusFront] = useState();
    const [needsReload, setNeedsReload] = useState(0);
    const [finalStep, setFinalStep] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const debug = false;

    const handleNext = async () => {
        setInProgress(true);
        if (stepDestinations.length === 1) {
            await performCommit();
        } else if (stepDestinations.length > 1) {
            await performInputValueAndCommit();
        }
        setSelectedValue(null);
        setInProgress(false);
        document.location.reload();
    };

    const performCommit = async () => {
        try {
            const result = await commitStep(id, userName, accessToken);
            setNeedsReload(prev => prev + 1);
            if (debug) console.log("commitStep - PP:", result);
        } catch (error) {
            console.error("Error committing step:", error);
        }
    };

    const performInputValueAndCommit = async () => {
        try {
            const result = await addInputToStep(id, selectedValue, userName, accessToken);
            setNeedsReload(prev => prev + 1);
            if (debug) console.log("addInputToStep - PP:", result);
        } catch (error) {
            console.error("Error adding input to step:", error);
        }
    };

    const handleSelect = (value) => {
        setSelectedValue(value);
    };

    useEffect(() => {
        if (debug) console.log("needsReload changed:", needsReload);
        const fetchData = async () => {
            try {
                const stepData = await getStep(id);
                setStepDescription(stepData.Step.id + ": " + stepData.Step.description);
                setStepDestinations(stepData.Destinations);
                setFinalStep(stepData.Step.id.startsWith("E."));

                let tempMetadata = await getApplicationMetadata(id);
                setStatusId(tempMetadata.status);
                setStatus(tempMetadata.statusNameInternal);
                setStatusFront(tempMetadata.statusName);
                setDisplayName(tempMetadata.displayName);
            } catch (error) {
                console.error("Error fetching step data:", error);
            }
        };

        fetchData();
    }, [needsReload, id]);

    useEffect(() => {
        if (debug) console.log("PP - uE - selectedValue: " + selectedValue);
    }, [selectedValue])

    if (((checkPermission("Obsługa wniosków") && operatorCanRunProcess(statusId))
        || (!checkPermission("Obsługa wniosków") && clientCanRunProcess(statusId))))
        return (
            <div className={embedded ? 'd-flex flex-column pt-1 mt-1 border-top' : 'd-flex flex-column pe-1 w19'}>
                {!embedded && (
                    <div>
                        <h3>OBSŁUGA WNIOSKU</h3>
                        <div className='ms-auto very-small'>
                            {stepDescription ?
                                (
                                    stepDescription.split(": ")[0]
                                ) : (
                                    <LoadingSpinner />
                                )}
                        </div>
                    </div>
                )}

                {/* {!embedded && (
                    <div className='mb-1'>
                        <label className="form-label">Nazwa robocza</label>
                        {displayName ? (
                            <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}>{displayName}</div>
                        ) : (
                            <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}><LoadingSpinner /></div>
                        )}
                    </div>
                )}

                {!checkPermission("Obsługa wniosków") && (
                    <div className='mb-1'>
                        <label className="form-label">Status</label>
                        {statusFront ? (
                            <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}>{statusFront}</div>
                        ) : (
                            <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}><LoadingSpinner /></div>
                        )}
                    </div>
                )}

                {!embedded && checkPermission("Obsługa wniosków") && (
                    <div className='mb-1'>
                        <label className="form-label">Status wewnętrzny</label>
                        {status ? (
                            <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}>{status}</div>
                        ) : (
                            <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}><LoadingSpinner /></div>
                        )}
                    </div>
                )} */}

                <div>
                    {stepDescription && embedded && (
                        <div className='w-100 d-flex flex-row align-items-center mb-1'>
                            <div>
                                <i className={`fs-1 bi bi-${finalStep ? 'check-circle-fill text-success' : (stepDestinations.length === 1 ? 'arrow-right-circle-fill text-danger' : 'question-circle-fill text-danger')}`} />
                            </div>

                            <div className='ms-1'>
                                <strong>{stepDescription.split(": ")[1]}</strong><br />
                                <span className='text-black-50'>Krok: {stepDescription.split(": ")[0]}</span>
                            </div>
                        </div>
                    )}

                    {stepDestinations && !embedded && (
                        <div className="form-label"><strong>{stepDescription.split(": ")[1]}</strong></div>
                    )}

                    {stepDestinations ?
                        (
                            stepDestinations.map((sd, index) => (
                                <div className='form-control rounded-3 w-100 p-1 mb-1 hand' key={index} onClick={() => handleSelect(sd.stepId)}>
                                    <div className='d-flex flex-row'>
                                        <input type="radio" className='form-check-input me-1' style={{ cursor: "pointer" }} name={"processOption" + index} id={"processOption" + index} checked={selectedValue === sd.stepId} onChange={() => handleSelect(sd.stepId)} />

                                        <label className="form-check-label" style={{ cursor: "pointer" }} htmlFor={"processOption" + index}>
                                            {sd.description}
                                        </label>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <LoadingSpinner />
                        )
                    }
                </div>
                {!finalStep && (
                    <div className='d-flex flex-row'>
                        {selectedValue ? (
                            inProgress ? (
                                <button className='btn btn-primary ms-auto w11 h2' disabled>
                                    <LoadingSpinner text='Przetwarzanie' />
                                </button>
                            ) : (
                                <button className='btn btn-primary ms-auto w11 h2' onClick={handleNext}>
                                    Dalej
                                </button>
                            )
  
                        ) : (
                            <button className='btn btn-primary ms-auto w11 h2' disabled>
                                Dalej
                            </button>
                        )}
                    </div>
                )}
            </div>
        );
}

export default ProcessPanel;
