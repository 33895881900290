import React, { useState, useEffect } from 'react';
import HelpTooltip from './HelpTooltip';

function ProductSelector({ name, index, data, productsList, onDataChange, required, label, tooltip, onBlurHandler }) {

    const [products, setProducts] = useState([]);

    const handleInput = (e) => {
        e.target.name = name;
        onDataChange(e.target.value);
    }

    useEffect(() => {

        setProducts(productsList);
    }, [productsList]);

    return (
        <div>
            <div className='d-flex flex-row'>
                <label className="form-label">{label + ((required === "true") ? " *" : "")}</label>
                {tooltip && (
                    <HelpTooltip title={tooltip} />
                )}
            </div>
            <select className="form-control form-select my-0 h2 rounded-3" name={"productSelect" + index} value={data} onChange={handleInput} onBlur={onBlurHandler} required={required === "true"}>
                <option value="">Wybierz ...</option>
                {products.map(product => (
                    <option key={product.productName} value={product.productName}>
                        {product.productName}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default ProductSelector;
