import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useParams } from 'react-router-dom';
import CommunicationMessage from './CommunicationMessage';
import { useAuth } from '../../context/AuthContext';
import { validateLettersDigitsSpecChars } from '../../utils/Validators';
import { updateMessages } from '../../services/Application';
import LoadingSpinner from './LoadingSpinner';

function CommunicationPanel({ isVisible, data, communicationCallbacks, embedded = false }) {

    const [conversationData, setConversationData] = useState();
    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState("");
    const [solved, setSolved] = useState(false);
    const [sending, setSending] = useState(false);
    const { userName, checkPermission } = useAuth();
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    let { step, id } = useParams();

    function getFormattedDate() {
        let date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są liczone od 0
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    // TODO: uzupełnianie authorRole
    const createMessage = () => {
        let message = {
            author: userName,
            authorRole: (checkPermission("Obsługa wniosków") ? "operator" : "wnioskodawca"),
            createDate: getFormattedDate(),
            readDate: "",
            text: messageText
        };

        return message;
    };

    const handleMessageInput = (e) => {
        const value = e.target.value;

        if (validateLettersDigitsSpecChars(value, 3000, false))
            setMessageText(value);
    };

    const handleSend = async () => {
        setSending(true);
        const newMessage = createMessage();
        await setMessages(prevMessages => [...prevMessages, newMessage]);
        setMessageText("");
        let newData = data;
        newData.Conversation = [...messages, newMessage]
        if (!embedded) communicationCallbacks[conversationData.id]("open");
        updateMessages(id, (embedded ? 5 : step), newData);
        scrollToBottom();
        setSending(false);
    };

    const handleMarkAsSolved = (e) => {
        setSolved(e.target.checked);

        let newData = data;
        if (e.target.checked) {
            newData.status = "solved";
        }
        else {
            newData.status = (newData.Conversation.length > 0) ? "open" : "none";
        }
        if (!embedded) communicationCallbacks[conversationData.id](newData.status);
        updateMessages(id, step, newData);
    };

    useEffect(() => {
        if (data) {
            setConversationData(data);
            setMessages(data.Conversation || []);
            setSolved(data.status === "solved");
        }
    }, [data]);

    if (!isVisible)
        return null;

    if (isVisible)
        return (
            <div className={'d-flex flex-column ' + (embedded ? '' : 'pe-1 pt-2 w19')}>
                {!embedded && (<h3>WIADOMOŚCI</h3>)}

                {conversationData ? (
                    <div className='p-0'>
                        {!embedded && (
                            <div className=' d-flex flex-row ps-auto mb-1 w-100'>
                                {conversationData.locationName && (
                                    <div className='ms-0 me-1 small'>{conversationData.locationName}</div>
                                )}
                                <div className='ms-auto me-0 very-small w1'>{conversationData.id}</div>
                            </div>
                        )}

                        {(messages.length > 1) && (<div className='d-flex flex-row mb-1'>
                            <a className='ms-auto' onClick={() => scrollToBottom()} style={{cursor: 'pointer'}}><i className='bi bi-arrow-down-circle'></i>&nbsp;{embedded ? "Przejdź do ostatniej wypowiedzi" : "Ostatnia wypowiedź"}</a>
                        </div>)}

                        {messages && (messages.length > 0) ? (
                            <div className='w-100 mb-1' style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                {messages.map((message, index) => (
                                        <CommunicationMessage
                                            author={message.author}
                                            authorRole={message.authorRole}
                                            createDate={message.createDate}
                                            readDate={message.readDate}
                                            text={message.text}
                                            key={index}
                                        />
                                    ))}
                                    <div ref={messagesEndRef} />
                            </div>
                        ) : (
                            <div>
                                Brak wiadomości
                            </div>
                        )
                        }
                    </div>

                ) : (
                    <div>
                        <div>Kliknij ikonę komentarza</div>
                        <div className='rounded-2 border p-0 d-flex justify-content-center align-items-center ms-1' style={{ width: '30px', height: '30px', borderWidth: '0px', backgroundColor: "red", color: "white", fontSize: "18px" }}>
                            <i className='bi-envelope'></i>
                        </div>
                        w formularzu, aby otworzyć konwersację.
                    </div>
                )}

                {conversationData && !solved && (
                    <div className={'d-flex flex-column ' + (embedded ? '' : 'mb-1 pb-1 border-bottom')}>
                        <textarea
                            className='form-control mb-1 h6'
                            style={{ backgroundColor: "white" }}
                            placeholder='Wprowadź treść wiadomości'
                            value={messageText}
                            onChange={handleMessageInput}
                        />

                        {sending ? (
                            <button type="submit" className={"btn btn-primary rounded-2 ms-auto h2 " + (embedded ? 'w11' : 'w7')} disabled>
                                <LoadingSpinner text='Wysyłanie' />
                            </button>
                        ) : (
                            messageText.length > 0 ? (
                                <button type="submit" className={"btn btn-primary rounded-2 ms-auto h2 " + (embedded ? 'w11' : 'w7')} onClick={() => handleSend()}>
                                    Wyślij
                                </button>
                            ) : (
                                <button type="submit" className={"btn btn-primary rounded-2 ms-auto h2 " + (embedded ? 'w11' : 'w7')} disabled>
                                    Wyślij
                                </button>
                            ))}
                    </div>
                )}

                {(!embedded && conversationData && checkPermission("Obsługa wniosków")) && (
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="mark-as-solved" checked={solved} onChange={handleMarkAsSolved} />
                        <label className="form-check-label" htmlFor="mark-as-solved">
                            Oznacz jako rozwiązane
                        </label>
                    </div>
                )}
            </div>
        );
}

export default CommunicationPanel;
