import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { ApiHost } from '../../utils/ApiConfig';
import StatusBadge from './StatusBadge';
import StatusTagSelect from './StatusTagSelect';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pl } from 'date-fns/locale';
registerLocale('pl', pl);

/**
 * DataTableApp component renders a data table with pagination, sorting, and filtering capabilities.
 *
 * @component
 * @param {Object[]} data - The data to be displayed in the table.
 * @param {number} itemsPerPage - Number of items to display per page.
 * @param {string[]} columnHeaders - Array of column headers for the table.
 *
 * @example
 * const data = [
 *   { NIP: '1234567890', Eksporter: 'Company A', Status: 'Active', 'Data utworzenia': '2023-01-01 12:00:00' },
 *   { NIP: '0987654321', Eksporter: 'Company B', Status: 'Inactive', 'Data utworzenia': '2023-02-01 12:00:00' }
 * ];
 * const itemsPerPage = 10;
 * const columnHeaders = ['NIP', 'Eksporter', 'Status', 'Data utworzenia', 'Action'];
 * 
 * <DataTableApp data={data} itemsPerPage={itemsPerPage} columnHeaders={columnHeaders} />
 *
 * @returns {JSX.Element} The rendered DataTableApp component.
 */
const DataTableApp = ({ data, itemsPerPage, columnHeaders, hiddenColumns = [], initialFilterStatuses = [], colorMode = 'Client' }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('Firma');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedData, setSortedData] = useState([]);
  const [filterNIP, setFilterNIP] = useState('');
  const [filterExporterName, setFilterExporterName] = useState('');
  const [filterStatuses, setFilterStatuses] = useState([]);
  const [filterOperatorName, setFilterOperatorName] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState(null);
  const [filterDateTo, setFilterDateTo] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { userId } = useAuth();

  const unlinkBusiness = async (businessId) => {
    try {
      console.log("Usuwanie powiązania użytkownika z firmą " + businessId);
      const response = await axios.post(ApiHost + `/business/unlink`, {
        user_id: userId,
        business_id: businessId
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.status === 200) {
        console.log("Usunięto powiązanie użytkownika [" + userId + "] z firmą");
      }

    } catch (error) {
      console.error("Błąd usuwania powiązania z firmą: ", error);
    }

  }

  function editApplication(businessId) {
    navigate('/application/edit/1/' + businessId, { replace: true });
  }

  useEffect(() => {
    let filteredData = data;
    if (filterNIP) {
      filteredData = filteredData.filter(item => item.NIP.includes(filterNIP));
    }
    if (filterExporterName) {
      filteredData = filteredData.filter(item => item.Eksporter.toLowerCase().includes(filterExporterName.toLowerCase()));
    }
    if (filterStatuses.length) {
      filteredData = filteredData.filter(item => filterStatuses.includes(item.Status));
    }
    if (filterOperatorName) {
      filteredData = filteredData.filter(item => item["Przypisany do"].toLowerCase().includes(filterOperatorName.toLowerCase()));
    }
    if (filterDateFrom) {
      filteredData = filteredData.filter(item => new Date(item['Data utworzenia']) >= filterDateFrom);
    }
    if (filterDateTo) {
      filteredData = filteredData.filter(item => new Date(item['Data utworzenia']) <= filterDateTo.setHours(23, 59, 59, 999));
    }
    if (sortColumn) {
      const sorted = [...filteredData].sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (a[sortColumn] > b[sortColumn]) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });
      setSortedData(sorted);
    } else {
      setSortedData(filteredData);
    }
  }, [sortColumn, sortOrder, data, filterNIP, filterExporterName, filterStatuses, filterOperatorName, filterDateFrom, filterDateTo]);

  useEffect(() => {
    setShowFilters(false);
    if (initialFilterStatuses.length){
      console.log('Setting filter statuses: ', initialFilterStatuses);
      setFilterStatuses(initialFilterStatuses);
      setShowFilters(true);
    }
    else
    {
      console.log('Initial filter statuses not set');
      setFilterStatuses([]);
    }
  }, [initialFilterStatuses]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="table-responsive">
      {!showFilters ? (
        <div className="d-flex flex-row mb-1">
          <button className="btn btn-outline-primary ms-auto w11 h2" onClick={() => setShowFilters(true)}><i className='bi bi-funnel me-1' />Pokaż filtry</button>
        </div>
      ) : (
        <div className='d-flex flex-column'>
          <div className="d-flex flex-row mb-1">
            <button className="btn btn-outline-primary ms-auto w11 h2" onClick={() => setShowFilters(false)}><i className='bi bi-funnel me-1' />Ukryj filtry</button>
          </div>
          <div className="d-flex flex-row border-top pt-1 mb-1">
            <div className="d-flex flex-column w13">
              <label htmlFor="filterNIP" className="form-label">NIP</label>
              <input
                type="text"
                id="filterNIP"
                className="form-control h2"
                placeholder='Wpisz fragment NIP'
                value={filterNIP}
                onChange={(e) => setFilterNIP(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column ms-1 w23">
              <label htmlFor="filterExporterName" className="form-label">Nazwa eksportera</label>
              <input
                type="text"
                id="filterExporterName"
                className="form-control h2"
                placeholder='Wpisz fragment nazwy eksportera'
                value={filterExporterName}
                onChange={(e) => setFilterExporterName(e.target.value)}
              />
            </div>
          </div>
          <div className='d-flex flex-row'>
            <div className="d-flex flex-column w13">
              <div className="d-flex flex-row">
                <label className="form-label">Data utworzenia</label>
              </div>
              <div className="d-flex flex-row mb-1">
                <DatePicker
                  selected={filterDateFrom}
                  onChange={date => setFilterDateFrom(date)}
                  selectsStart
                  startDate={filterDateFrom}
                  endDate={filterDateTo}
                  placeholderText="Od"
                  locale="pl"
                  dateFormat="yyyy-MM-dd"
                  className="form-control rounded-end-0 border-end-0 w5 h2" />
                <div className="form-control rounded-0 border-start-0 border-end-0 w3 px-1 h2">
                  <i className="bi bi-arrow-right"></i>
                </div>
                <DatePicker
                  selected={filterDateTo}
                  onChange={date => setFilterDateTo(date)}
                  selectsEnd
                  startDate={filterDateFrom}
                  endDate={filterDateTo}
                  placeholderText="Do"
                  locale="pl"
                  dateFormat="yyyy-MM-dd"
                  className="form-control rounded-start-0 border-start-0 w5 h2" />
              </div>
            </div>
            <div className="d-flex flex-column ms-1 w23">
              <label htmlFor="filterOperatorName" className="form-label">Przypisany do</label>
              <input
                type="text"
                id="filterOperatorName"
                className="form-control h2"
                placeholder='Wpisz fragment nazwy operatora'
                value={filterOperatorName}
                onChange={(e) => setFilterOperatorName(e.target.value)}
              />
            </div>
          </div>
          <div className="border-bottom pb-1 mb-1">
            <label htmlFor="filterStatus" className="form-label">Status</label>
            <StatusTagSelect onChange={setFilterStatuses} data={filterStatuses} />
          </div>
        </div>
      )}
      <table className="table">
        <thead>
          <tr>
            {columnHeaders.map((header, index) => (
              !hiddenColumns.includes(header) && (
                <th key={index} onClick={() => handleSort(header)} style={{ cursor: 'pointer' }}>
                  {header !== "Action" ? header : ""}
                  {sortColumn === header && (
                    sortOrder === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                  )}
                </th>
              )))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.keys(row).map((key, columnIndex) => (
                !hiddenColumns.includes(columnHeaders[columnIndex]) && (
                  <td key={columnIndex}>
                    {columnHeaders[columnIndex] === "Action" ? (
                      <div className='d-inline-block'>
                        <button className="btn btn-outline-primary" onClick={() => editApplication(row[key])}>Otwórz</button>
                      </div>
                    ) : (
                      columnHeaders[columnIndex] === "Data utworzenia" ? (
                        <div>
                          {row[key].split(' ')[0]}<br />{row[key].split(' ')[1]}
                        </div>
                      ) : (
                        columnHeaders[columnIndex] === "Status" ? (
                          <StatusBadge text={row[key]} colorMode={colorMode} />
                        ) : (
                          row[key]
                        )
                      )
                    )}
                  </td>
                )))}
            </tr>
          ))}
        </tbody>
      </table>
      <nav>
        <ul className="pagination">
          {Array.from({ length: Math.ceil(sortedData.length / itemsPerPage) }, (_, i) => i + 1).map(pageNumber => (
            <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
              <button onClick={() => paginate(pageNumber)} className="page-link">{pageNumber}</button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default DataTableApp;